import gql from 'graphql-tag';

const UserReservationsQuery = gql`
  query UserReservations($userId: Int) {
    userReservations(userId: $userId) {
      id
      start_datetime
      end_datetime
      title
      spaces {
        id
        name
      }
      created_at
      updated_at
      status
      status_ko
      status_color
    }
  }
`;

export default UserReservationsQuery;
