import React from 'react';
import { Mutation } from 'react-apollo';
import { Button } from 'semantic-ui-react';
import moment from 'moment';
import 'moment/locale/ko';
import { AdminUpdateReservationMutation } from '../../graphql/reservations';

const ReservationApprovalButtons = ({ reservation, size }) => {
  const { id, status, end_datetime } = reservation;
  const cancelled = status === 'cancelled';
  const approved = status === 'approved';
  const rejected = status === 'rejected';

  const isBefore = moment(end_datetime * 1000).isBefore(Date.now());
  return (
    <Mutation mutation={AdminUpdateReservationMutation}>
      {(adminUpdateReservation, { loading }) => {
        return (
          <Button.Group size={size || 'mini'}>
            <Button
              disabled={loading || isBefore || cancelled}
              content="승인"
              color={approved ? 'green' : null}
              onClick={() => {
                if (approved) {
                  adminUpdateReservation({
                    variables: { id, status: 'pending' }
                  });
                } else {
                  if (window.confirm(`정말로 대관을 승인하겠습니까?`)) {
                    adminUpdateReservation({
                      variables: { id, status: 'approved' }
                    }).catch(error => {
                      if (
                        error.graphQLErrors &&
                        error.graphQLErrors.length > 0
                      ) {
                        alert(error.graphQLErrors[0].message);
                      }
                    });
                  }
                }
              }}
            />
            <Button
              disabled={loading || isBefore || cancelled}
              content="거절"
              color={rejected ? 'red' : null}
              onClick={() => {
                if (rejected) {
                  adminUpdateReservation({
                    variables: { id, status: 'pending' }
                  });
                } else {
                  if (window.confirm(`정말로 대관을 거절하겠습니까?`)) {
                    adminUpdateReservation({
                      variables: { id, status: 'rejected' }
                    }).catch(error => {
                      if (
                        error.graphQLErrors &&
                        error.graphQLErrors.length > 0
                      ) {
                        alert(error.graphQLErrors[0].message);
                      }
                    });
                  }
                }
              }}
            />
          </Button.Group>
        );
      }}
    </Mutation>
  );
};

export default ReservationApprovalButtons;
