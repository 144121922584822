import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';
import { AdminUpdateUserMutation, UserQuery } from '../../graphql/users';
import { UserForm } from './';

function EditUser({ match, history }) {
  const { id } = match.params;
  const { loading, data } = useQuery(UserQuery, {
    variables: { id },
  });
  const [adminUpdateUser, { loading: adminUpdateUserLoading }] = useMutation(
    AdminUpdateUserMutation,
  );

  if (loading)
    return (
      <Dimmer active inverted>
        <Loader active inline="centered" />
      </Dimmer>
    );
  if (!data || !data.user) return null;
  const { user } = data;
  return (
    <UserForm
      user={user}
      loading={adminUpdateUserLoading}
      submit={adminUpdateUser}
      history={history}
    />
  );
}

EditUser.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  history: PropTypes.object.isRequired,
};

export default EditUser;
