import React, { Component } from 'react';
import { Button, Container, Divider, Form, Grid, Header, Radio, Select } from 'semantic-ui-react';
import { Prompt } from 'react-router-dom';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { ReservationAgendaUploader } from '.';

export default class ReservationForm extends Component {
  constructor(props) {
    super(props);
    const { reservation } = props;
    if (reservation) {
      this.state = {
        id: Number(reservation.id),
        eventDate: moment(reservation.start_datetime * 1000)._d,
        startTime: moment(reservation.start_datetime * 1000),
        endTime: moment(reservation.end_datetime * 1000),
        spaceIds: reservation.space_ids,
        category: reservation.category,
        name: reservation.name,
        institution: reservation.institution,
        email: reservation.email,
        phone: reservation.phone,
        title: reservation.title,
        numberOfParticipants: reservation.number_of_participants,
        purpose: reservation.purpose,
        isPublic: reservation.is_public,
        isFree: reservation.cost === null,
        cost: Number(reservation.cost),
        microphone: reservation.microphone,
        projector: reservation.projector,
        soundEquipment: reservation.sound_equipment,
        hasEquipmentRequest: !!reservation.equipment_request,
        equipmentRequest: reservation.equipment_request || '',
        catering: reservation.catering,
        banner: reservation.banner,
        description: reservation.description,
        promotion: reservation.promotion,
        website: reservation.website,
        agreedToTerms: true,
        submitted: false,
        agendaSignedId: null,
        fileName: reservation.agenda_filename,
      };
    } else {
      const spaceIds = [];
      if (props.history.location.spaceId) {
        spaceIds.push(props.history.location.spaceId);
      }
      this.state = {
        eventDate: '',
        startTime: null,
        endTime: null,
        spaceIds,
        category: '',
        name: '',
        institution: '',
        email: '',
        phone: '',
        title: '',
        numberOfParticipants: '',
        purpose: '',
        isPublic: true,
        isFree: true,
        microphone: false,
        projector: false,
        soundEquipment: false,
        hasEquipmentRequest: false,
        equipmentRequest: '',
        catering: false,
        banner: false,
        promotion: false,
        website: '',
        agreedToTerms: false,
        submitted: false,
        agendaSignedId: null,
        fileName: '',
      };
    }
  }

  componentDidUpdate() {
    const { submitted } = this.state;
    window.onbeforeunload = !submitted ? () => true : null;
  }

  setSubmitted = submitted => {
    this.setState({ submitted });
  };

  removeFileName = () => {
    this.setState({
      fileName: '',
      agendaSignedId: null,
    });
  };
  setFileName = e => {
    this.setState({
      fileName: e.target.files[0].name,
    });
  };

  setAgendaSignedId = signedIds => {
    this.setState({ agendaSignedId: signedIds[0] });
  };

  handleSubmit = () => {
    const {
      id,
      eventDate,
      startTime,
      endTime,
      spaceIds,
      category,
      name,
      institution,
      email,
      phone,
      title,
      numberOfParticipants,
      purpose,
      isPublic,
      isFree,
      cost,
      microphone,
      projector,
      soundEquipment,
      hasEquipmentRequest,
      equipmentRequest,
      catering,
      banner,
      description,
      promotion,
      website,
      agreedToTerms,
      agendaSignedId,
    } = this.state;
    const { submit, history } = this.props;
    if (!eventDate) {
      alert('대관 날짜를 입력해주세요.');
    } else if (!startTime) {
      alert('시작 시간을 입력해주세요.');
    } else if (!endTime) {
      alert('종료 시간을 입력해주세요.');
    } else if (spaceIds.length < 1) {
      alert('신청 공간을 선택해주세요.');
    } else if (!category) {
      alert('대관 유형을 선택해주세요.');
    } else if (!name) {
      alert('이름을 입력해주세요.');
    } else if (!institution) {
      alert('주최 기관명을 입력해주세요.');
    } else if (!email) {
      alert('이메일을 입력해주세요.');
    } else if (!phone) {
      alert('연락처를 입력해주세요.');
    } else if (!title) {
      alert('모임명을 입력해주세요.');
    } else if (!numberOfParticipants) {
      alert('참석자 수를 입력해주세요.');
    } else if (!purpose) {
      alert('대관목적을 입력해주세요.');
    } else if (!isFree && !cost) {
      alert('참석 비용을 입력해주세요.');
    } else if (
      hasEquipmentRequest &&
      (!equipmentRequest || equipmentRequest.match(/^ *$/) !== null)
    ) {
      alert('필요장비를 입력해주세요.');
    } else if (!description) {
      alert('모임 상세정보를 입력해주세요.');
    } else if (!id && !agreedToTerms) {
      alert('아쇼카 한국의 개인정보보호 방침 및 사용 약관을 동의해주세요.');
    } else {
      const startDatetime = new Date(
        eventDate.getFullYear(),
        eventDate.getMonth(),
        eventDate.getDate(),
        startTime.get('hour'),
        startTime.get('minute'),
        0,
      );
      const endDatetime = new Date(
        eventDate.getFullYear(),
        eventDate.getMonth(),
        eventDate.getDate(),
        endTime.get('hour'),
        endTime.get('minute'),
        0,
      );
      const hash = {
        id,
        start_datetime: moment(startDatetime).unix(),
        end_datetime: moment(endDatetime).unix(),
        space_ids: spaceIds,
        category,
        name,
        institution,
        email,
        phone,
        title,
        number_of_participants: Number(numberOfParticipants),
        purpose,
        is_public: isPublic,
        cost: isFree ? null : Number(cost),
        microphone,
        projector,
        sound_equipment: soundEquipment,
        equipment_request: hasEquipmentRequest ? equipmentRequest : null,
        catering,
        banner,
        description,
        promotion,
        website,
        agreed_to_terms: agreedToTerms,
        agenda_signed_id: agendaSignedId || null,
      };
      if (window.confirm('정말로 이벤트를 등록하시겠습니까?')) {
        submit({ variables: hash })
          .then(response => {
            if (response.data) {
              this.setSubmitted(true);
              let link;
              if (id) {
                link = `/reservations/${response.data.adminUpdateReservation.id}`;
              } else {
                alert('대관 신청이 완료되었습니다.');
                link = `/reservations/${response.data.adminCreateReservation.id}`;
              }
              history.push(link);
            }
          })
          .catch(error => {
            if (error.graphQLErrors && error.graphQLErrors.length > 0) {
              alert(error.graphQLErrors[0].message);
            }
          });
      }
    }
  };

  render() {
    const {
      eventDate,
      startTime,
      endTime,
      spaceIds,
      category,
      name,
      institution,
      email,
      phone,
      title,
      numberOfParticipants,
      purpose,
      agreedToTerms,
      banner,
      catering,
      isFree,
      cost,
      isPublic,
      promotion,
      microphone,
      projector,
      soundEquipment,
      hasEquipmentRequest,
      equipmentRequest,
      description,
      website,
      submitted,
      fileName,
    } = this.state;
    const { reservation, history } = this.props;

    const categoryOptions = [
      { key: 0, text: '시그니처', value: 'signature' },
      { key: 1, text: '팀 오브 팀스', value: 'team_of_teams' },
      { key: 2, text: '아쇼카', value: 'ashoka' },
      { key: 3, text: '헤이그라운드', value: 'heyground' },
      { key: 4, text: '일반대관', value: 'general' },
    ];

    const purposeOptions = [
      { key: 0, text: '컨퍼런스', value: 'conference' },
      { key: 1, text: '워크샵', value: 'workshop' },
      { key: 2, text: '세미나', value: 'seminar' },
      { key: 3, text: '기타', value: 'others' },
    ];

    const disabledStartHours = () => {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 23];
    };

    const disabledEndHours = () => {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 23];
    };

    return (
      <Container className="pv-3" textAlign="center">
        <Grid>
          <Grid.Row>
            <Grid.Column only="tablet computer" tablet={3} computer={3} largeScreen={3} />
            <Grid.Column mobile={16} tablet={10} computer={10} largeScreen={10}>
              <Form onSubmit={this.handleSubmit} size="large" className="ta-l">
                {/* 대관 시간 및 공간 정보 */}
                <Header as="h2" content="대관 시간 및 공간 정보" className="ta-c" />
                <Form.Field>
                  <label>대관 날짜</label>
                  <DayPickerInput
                    value={eventDate}
                    dayPickerProps={{
                      localeUtils: MomentLocaleUtils,
                      locale: 'ko',
                      disabledDays: [
                        {
                          before: new Date(),
                        },
                      ],
                    }}
                    placeholder="YYYY-MM-DD"
                    onDayChange={date => this.setState({ eventDate: date })}
                  />
                </Form.Field>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>시작 시간</label>
                    <TimePicker
                      defaultValue={startTime}
                      minuteStep={30}
                      showSecond={false}
                      onChange={value => {
                        this.setState({ startTime: value || '' });
                      }}
                      inputReadOnly
                      disabledHours={disabledStartHours}
                      hideDisabledOptions
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>종료 시간</label>
                    <TimePicker
                      defaultValue={endTime}
                      minuteStep={30}
                      showSecond={false}
                      onChange={value => this.setState({ endTime: value || '' })}
                      inputReadOnly
                      disabledHours={disabledEndHours}
                      hideDisabledOptions
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group grouped className="mt-2 mb-q">
                  <label>신청 공간</label>
                  <Form.Checkbox
                    className="mb-h"
                    checked={spaceIds.includes(1)}
                    onChange={() => {
                      const index = spaceIds.indexOf(1);
                      if (index !== -1) {
                        this.setState({
                          spaceIds: spaceIds.filter(id => id !== 1),
                        });
                      } else {
                        this.setState({ spaceIds: [...spaceIds, 1] });
                      }
                    }}
                    label={
                      <label>
                        다목적홀(영감) <small className="gray">100~150명</small>
                      </label>
                    }
                  />
                  <Form.Checkbox
                    className="mb-h"
                    checked={spaceIds.includes(2)}
                    onChange={() => {
                      const index = spaceIds.indexOf(2);
                      if (index !== -1) {
                        this.setState({
                          spaceIds: spaceIds.filter(id => id !== 2),
                        });
                      } else {
                        this.setState({ spaceIds: [...spaceIds, 2] });
                      }
                    }}
                    label={
                      <label>
                        세미나룸(공감) <small className="gray">50~80명</small>
                      </label>
                    }
                  />
                </Form.Group>
                <Form.Field
                  className="mt-2"
                  required
                  control={Select}
                  label="대관 유형"
                  options={categoryOptions}
                  placeholder="대관 유형을 선택해주세요."
                  value={category}
                  onChange={(e, { value }) => {
                    this.setState({ category: value });
                  }}
                />
                <Divider className="mv-2 mh-20p" />
                {/* 신청자 정보 */}
                <Header as="h2" content="신청자 정보" className="ta-c" />
                <Form.Input
                  required
                  fluid
                  label="이름"
                  placeholder="홍길동"
                  value={name}
                  onChange={e => this.setState({ name: e.target.value })}
                />
                <Form.Input
                  required
                  fluid
                  label="주최 기관명"
                  value={institution}
                  onChange={e => this.setState({ institution: e.target.value })}
                />
                <Form.Input
                  required
                  fluid
                  label="이메일"
                  placeholder="yourname@email.com"
                  value={email}
                  onChange={e => this.setState({ email: e.target.value })}
                />
                <Form.Input
                  required
                  fluid
                  label="연락처"
                  placeholder="010-1234-5678"
                  value={phone}
                  onChange={e => this.setState({ phone: e.target.value })}
                />
                <Divider className="mv-2 mh-20p" />
                {/* 모임 정보 */}
                <Header as="h2" content="모임 정보" className="ta-c" />
                <Form.Input
                  required
                  fluid
                  label="모임명"
                  value={title}
                  onChange={e => this.setState({ title: e.target.value })}
                />
                <Form.Input
                  required
                  fluid
                  label="참석자 수"
                  type="number"
                  value={numberOfParticipants}
                  onChange={e =>
                    this.setState({
                      numberOfParticipants: e.target.value,
                    })
                  }
                />
                <Form.Field
                  required
                  control={Select}
                  label="대관목적"
                  options={purposeOptions}
                  placeholder="선택해주세요."
                  value={purpose}
                  onChange={(e, { value }) => this.setState({ purpose: value })}
                />
                <Form.Group inline>
                  <label>공개 여부</label>
                  <Form.Field
                    control={Radio}
                    label="공개"
                    checked={isPublic}
                    onChange={() => this.setState({ isPublic: true })}
                  />
                  <Form.Field
                    control={Radio}
                    label="비공개"
                    checked={isPublic === false}
                    onChange={() => this.setState({ isPublic: false })}
                  />
                </Form.Group>
                <Form.Group inline>
                  <label>모임 유/무료 여부</label>
                  <Form.Field
                    control={Radio}
                    label="무료"
                    checked={isFree}
                    onChange={() => this.setState({ isFree: true, cost: null })}
                  />
                  <Form.Field
                    control={Radio}
                    label="유료"
                    checked={isFree === false}
                    onChange={() => this.setState({ isFree: false, cost: '' })}
                  />
                </Form.Group>
                {!isFree && (
                  <Form.Input
                    fluid
                    label="참석비용"
                    type="number"
                    value={cost}
                    onChange={e =>
                      this.setState({
                        cost: e.target.value,
                      })
                    }
                  />
                )}
                <Form.Group grouped className="mt-2 mb-q">
                  <label>필요장비</label>
                </Form.Group>
                <Form.Group inline className="mb-0">
                  <Form.Checkbox
                    className="mb-h"
                    checked={microphone}
                    onChange={() => this.setState({ microphone: !microphone })}
                    label={<label>마이크</label>}
                  />
                  <Form.Checkbox
                    className="mb-h"
                    checked={projector}
                    onChange={() => this.setState({ projector: !projector })}
                    label={<label>프로젝터</label>}
                  />
                  <Form.Checkbox
                    className="mb-h"
                    checked={soundEquipment}
                    onChange={() => this.setState({ soundEquipment: !soundEquipment })}
                    label={<label>음향</label>}
                  />
                  <Form.Checkbox
                    className="mb-h"
                    checked={hasEquipmentRequest}
                    onChange={() =>
                      this.setState({
                        hasEquipmentRequest: !hasEquipmentRequest,
                      })
                    }
                    label={<label>기타</label>}
                  />
                </Form.Group>
                {hasEquipmentRequest && (
                  <Form.Input
                    value={equipmentRequest}
                    onChange={e =>
                      this.setState({
                        equipmentRequest: e.target.value,
                      })
                    }
                    fluid
                    placeholder="기타 필요장비 입력"
                  />
                )}
                <Form.Group inline className="mt-2">
                  <label>케이터링</label>
                  <Form.Field
                    control={Radio}
                    label="예"
                    checked={catering}
                    onChange={() => this.setState({ catering: true })}
                  />
                  <Form.Field
                    control={Radio}
                    label="아니오"
                    checked={catering === false}
                    onChange={() => this.setState({ catering: false })}
                  />
                </Form.Group>
                <Form.Group inline>
                  <label>현수막/포스터 부착</label>
                  <Form.Field
                    control={Radio}
                    label="예"
                    checked={banner}
                    onChange={() => this.setState({ banner: true })}
                  />
                  <Form.Field
                    control={Radio}
                    label="아니오"
                    checked={banner === false}
                    onChange={() => this.setState({ banner: false })}
                  />
                </Form.Group>
                <Divider className="mv-2 mh-20p" />

                {/* 모임 상세정보 */}
                <Header as="h2" content="모임 상세정보" className="ta-c" />
                <Form.TextArea
                  required
                  className="mb-0"
                  label="상세정보"
                  placeholder="모임에 대한 상세사항을 필수로 적어주세요."
                  value={description}
                  onChange={e => this.setState({ description: e.target.value })}
                />

                <ReservationAgendaUploader
                  reservation={reservation}
                  fileName={fileName}
                  removeFileName={this.removeFileName}
                  setFileName={this.setFileName}
                  setAgendaSignedId={this.setAgendaSignedId}
                />

                <Form.Group grouped className="mt-2 mb-q">
                  <label>홍보 유무</label>
                  <p className="small mb-0">
                    체인지메이커스 웹사이트/SNS를 통해 행사 홍보가 필요하신가요?
                  </p>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field
                    control={Radio}
                    label="예"
                    checked={promotion}
                    onChange={() => this.setState({ promotion: true })}
                  />
                  <Form.Field
                    control={Radio}
                    label="아니오"
                    checked={promotion === false}
                    onChange={() => this.setState({ promotion: false })}
                  />
                </Form.Group>
                <Form.Input
                  fluid
                  label="행사정보 링크"
                  placeholder="www.yourwebsite.com"
                  value={website}
                  onChange={e => this.setState({ website: e.target.value })}
                />

                <Divider className="mv-2 mh-20p" />
                <Form.Checkbox
                  className="mb-h"
                  checked={agreedToTerms}
                  onChange={() => this.setState({ agreedToTerms: !agreedToTerms })}
                  label={
                    <label>
                      체인지메이커스{' '}
                      <a className="fw-b" href="/terms" target="_blank" rel="noopener noreferrer">
                        이용약관
                      </a>
                      과{' '}
                      <a className="fw-b" href="/privacy" target="_blank" rel="noopener noreferrer">
                        개인정보처리방침
                      </a>
                      , 공간 상세 정보와 대관 규정을 읽고 동의합니다.
                    </label>
                  }
                />
                <div className="ta-c mt-2">
                  <Button
                    disabled={!agreedToTerms}
                    size="large"
                    primary
                    content="등록"
                    type="submit"
                  />
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      history.goBack();
                    }}
                    size="large"
                    content="이전"
                  />
                </div>
                <Prompt
                  when={!submitted}
                  message="정말 이 페이지를 나가시겠습니까? 변경사항이 저장되지 않을 수 있습니다."
                />
              </Form>
            </Grid.Column>

            <Grid.Column only="tablet computer" tablet={3} computer={3} largeScreen={3} />
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}
