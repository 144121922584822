import gql from 'graphql-tag';

const UsersQuery = gql`
  query Users($offset: Int, $limit: Int, $query: String) {
    users(offset: $offset, limit: $limit, query: $query) {
      id
      name
      company
      phone
      email
    }
  }
`;

export default UsersQuery;
