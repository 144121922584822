import PropTypes from 'prop-types';
import React from 'react';
import { Menu } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { ApolloConsumer } from 'react-apollo';
import { StatusContext } from '../StatusContext';

const Navbar = ({ history }) => (
  <Menu>
    <Menu.Item as={Link} name="캘린더" to="/" />
    <Menu.Item as={Link} name="대관 관리" to="/reservations/general/all" />
    {/* <Menu.Item as={Link} name="스토리" to="/stories" /> */}
    <Menu.Item as={Link} name="회원 목록" to="/users" />
    <Menu.Item as={Link} name="이벤트 목록" to="/events" />
    <Menu.Item as={Link} name="리포트" to="/reports" />

    <ApolloConsumer>
      {client => (
        <StatusContext.Consumer>
          {({ authenticated, logout }) => {
            if (authenticated)
              return (
                <Menu.Item
                  name="로그아웃"
                  onClick={() => {
                    localStorage.removeItem('token');
                    localStorage.removeItem('userId');
                    logout();
                    client.resetStore();
                    history.push(`/`);
                  }}
                />
              );
            return <Menu.Item name="로그인" as={Link} to="/login" />;
          }}
        </StatusContext.Consumer>
      )}
    </ApolloConsumer>
  </Menu>
);

Navbar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default withRouter(Navbar);
