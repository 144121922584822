import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';
import { ReservationForm } from '.';
import { AdminUpdateReservationMutation, ReservationQuery } from '../../graphql/reservations';

function EditReservation({ match, history }) {
  const { id } = match.params;
  const { loading, data } = useQuery(ReservationQuery, {
    variables: { id },
  });
  const [adminUpdateReservation, { loading: adminUpdateReservationLoading }] = useMutation(
    AdminUpdateReservationMutation,
  );

  if (loading)
    return (
      <Dimmer active inverted>
        <Loader active inline="centered" />
      </Dimmer>
    );
  if (!data || !data.reservation) return null;
  const { reservation } = data;
  return (
    <ReservationForm
      reservation={reservation}
      loading={adminUpdateReservationLoading}
      submit={adminUpdateReservation}
      history={history}
    />
  );
}

EditReservation.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  history: PropTypes.object.isRequired,
};

export default EditReservation;
