import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

export const DESTROY_STORY = gql`
  mutation adminDestroyStory($id: ID!) {
    adminDestroyStory(id: $id) {
      id
    }
  }
`;

function StoryFormActions({ id, title, setSubmitted, history }) {
  const [adminDestroyStory, { loading }] = useMutation(DESTROY_STORY);
  return (
    <div className="mt-2">
      <Button size="large" primary content="등록" type="submit" />
      <Button
        onClick={e => {
          e.preventDefault();
          history.goBack();
        }}
        size="large"
        content="이전"
      />
      {!!id && (
        <Button
          className="bs-none bg-transparent ph-0"
          basic
          size="large"
          content="삭제"
          floated="right"
          loading={loading}
          onClick={e => {
            e.preventDefault();
            if (window.confirm(`정말로 '${title}' 스토리를 삭제하겠습니까?`)) {
              adminDestroyStory({
                variables: {
                  id,
                },
              }).then(() => {
                setSubmitted(true);
                alert(`'${title}' 스토리를 삭제했습니다.`);
                history.push('/stories');
              });
            }
          }}
        />
      )}
    </div>
  );
}

StoryFormActions.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  setSubmitted: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
};

export default withRouter(StoryFormActions);
