import React from 'react';
import { Mutation } from 'react-apollo';
import { Button } from 'semantic-ui-react';
import moment from 'moment';
import 'moment/locale/ko';
import { AdminUpdateReservationMutation } from '../../graphql/reservations';

const ReservationCancelButton = ({ reservation, size }) => {
  const { end_datetime, status, id } = reservation;
  const isBefore = moment(end_datetime * 1000).isBefore(Date.now());
  const cancelled = status === 'cancelled';
  return (
    <Mutation mutation={AdminUpdateReservationMutation}>
      {(adminUpdateReservation, { loading }) => {
        return (
          <Button
            size={size || 'mini'}
            color={cancelled ? 'yellow' : null}
            disabled={loading || isBefore}
            content="대관 취소"
            onClick={() => {
              if (cancelled) {
                adminUpdateReservation({
                  variables: { id, status: 'pending' }
                });
              } else {
                if (window.confirm('정말로 대관을 취소하시겠습니까?')) {
                  adminUpdateReservation({
                    variables: { id: id, status: 'cancelled' }
                  })
                    .then(_ => alert(`대관 취소가 완료되었습니다.`))
                    .catch(error => {
                      if (
                        error.graphQLErrors &&
                        error.graphQLErrors.length > 0
                      ) {
                        alert(error.graphQLErrors[0].message);
                      }
                    });
                }
              }
            }}
          />
        );
      }}
    </Mutation>
  );
};

export default ReservationCancelButton;
