import gql from 'graphql-tag';

const EventQuery = gql`
  query Event($id: ID!) {
    event(id: $id) {
      id
      title
      name
      start_datetime
      end_datetime
      created_at
      space {
        id
        name
      }
      email
      phone
      institution
      number_of_participants
      by_opencollege
    }
  }
`;
export default EventQuery;
