import React, { Component } from 'react';

export default class EditableLabel extends Component {
  constructor(props) {
    super(props);
    const { isEditing, text } = this.props;
    this.state = {
      isEditing: isEditing || false,
      text: text || ''
    };
  }

  handleFocus = () => {
    const { isEditing, text } = this.state;
    const { onFocusOut, onFocus } = this.props;
    if (isEditing) {
      if (typeof onFocusOut === 'function') {
        onFocusOut(text);
      }
    } else {
      if (typeof onFocus === 'function') {
        onFocus(text);
      }
    }
    this.setState({ isEditing: !isEditing });
  };

  handleChange = () => {
    this.setState({ text: this.textInput.value });
  };

  render() {
    const { isEditing, text } = this.state;
    const {
      inputPlaceHolder,
      inputMaxLength,
      inputTabIndex,
      blankText
    } = this.props;
    if (isEditing) {
      return (
        <div className="ui mini input">
          <input
            type="text"
            ref={input => {
              this.textInput = input;
            }}
            value={text}
            onChange={this.handleChange}
            onBlur={this.handleFocus}
            maxLength={inputMaxLength}
            placeholder={inputPlaceHolder}
            tabIndex={inputTabIndex}
            autoFocus
          />
        </div>
      );
    }
    if (text.trim() !== '')
      return <label onClick={this.handleFocus}>{text}</label>;
    return (
      <label onClick={this.handleFocus} className="silver">
        {blankText || '-'}
      </label>
    );
  }
}
