import * as qs from 'query-string';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { Container, Loader, Pagination, Table } from 'semantic-ui-react';
import { ReservationDetailTab, ReservationStatusTab } from '.';
import { AdminReservationsQuery, ReservationsCountQuery } from '../../graphql/reservations';
import { ReservationDetailHeader, ReservationItemDetail } from './items';

class ReservationsDetail extends Component {
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = event => {
    const { history, match } = this.props;
    const { status } = match.params;
    const params = qs.parse(history.location.search);
    const page = params.page ? Number(params.page) : 1;

    switch (event.keyCode) {
      case 37:
        if (page > 1) {
          this.props.history.push(`/reservations/detail/${status}/?page=${page - 1}`);
        }
        break;
      case 39:
        this.props.history.push(`/reservations/detail/${status}/?page=${page + 1}`);
        break;
      default:
        break;
    }
  };

  render() {
    const { history, match } = this.props;
    const { status } = match.params;
    const params = qs.parse(history.location.search);
    const page = params.page ? Number(params.page) : 1;

    return (
      <Query query={AdminReservationsQuery} variables={{ offset: 20 * (page - 1), status }}>
        {({ loading, data }) => {
          if (loading) {
            return <Loader active inline="centered" className="mv-3" />;
          }
          if (!data || !data.adminReservations) return null;
          const { adminReservations } = data;
          return (
            <Container fluid className="pb-3">
              <ReservationDetailTab page={page} status={status} />
              <ReservationStatusTab detail />
              <Table celled unstackable className="table-detail" size="small">
                <ReservationDetailHeader />

                <Table.Body>
                  {adminReservations.map(event => (
                    <ReservationItemDetail key={event.id} event={event} />
                  ))}
                </Table.Body>
              </Table>
              <Query query={ReservationsCountQuery} variables={{ status }}>
                {({ data: countData }) => {
                  if (!countData || !countData.reservationsCount) return null;
                  const { reservationsCount } = countData;
                  if (reservationsCount < 20) return null;
                  return (
                    <Pagination
                      activePage={page}
                      totalPages={reservationsCount ? Math.ceil(reservationsCount / 20) : 100}
                      onPageChange={(_e, { activePage }) => {
                        history.push(`/reservations/detail/${status}/?page=${activePage}`);
                      }}
                    />
                  );
                }}
              </Query>
            </Container>
          );
        }}
      </Query>
    );
  }
}

ReservationsDetail.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default ReservationsDetail;
