import React, { Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/ko';
import { Mutation } from 'react-apollo';
import { Link } from 'react-router-dom';
import { Button, Container, Dimmer, Header, Label, Loader, Table } from 'semantic-ui-react';
import { ReservationApprovalButtons, ReservationCancelButton } from '.';
import { AdminUpdateReservationMutation, ReservationQuery } from '../../graphql/reservations';
import { EditableLabel, SplitContent, TableRow, Time } from '../commons';

function Reservation({ match }) {
  const { id } = match.params;
  const { loading, data } = useQuery(ReservationQuery, {
    variables: { id },
  });
  if (loading)
    return (
      <Dimmer active inverted>
        <Loader active inline="centered" />
      </Dimmer>
    );
  if (!data || !data.reservation) return null;
  const { reservation } = data;
  const {
    status_ko,
    status_color,
    spaces,
    created_at,
    start_datetime,
    end_datetime,
    category_ko,
    category_color,
    name,
    institution,
    email,
    phone,
    title,
    number_of_participants,
    purpose_ko,
    is_public,
    cost,
    microphone,
    projector,
    sound_equipment,
    equipment_request,
    catering,
    banner,
    description,
    promotion,
    website,
    agenda_url,
    agenda_filename,
    agreed_to_terms,
    comment,
  } = reservation;

  const valueText = value => {
    return value ? '예' : '아니오';
  };

  const tableTitle = t => {
    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="2" content={t} />
        </Table.Row>
      </Table.Header>
    );
  };
  return (
    <Container className="pv-3" textAlign="center">
      <Header as="h2" content="대관 신청내역" />
      <ReservationApprovalButtons reservation={reservation} size="medium" />{' '}
      <Button.Group>
        <ReservationCancelButton reservation={reservation} size="medium" />
      </Button.Group>
      <Table basic="very" className="mt-2" unstackable>
        {tableTitle('대관시간 및 공간 정보')}
        <Table.Body>
          <TableRow title="승인상태">
            <Label content={status_ko} color={status_color} />
          </TableRow>
          <TableRow title="작성일시">
            <Time unix={created_at} format="LL dddd a h:mm" />
          </TableRow>
          <TableRow title="대관일시">
            {moment(start_datetime * 1000).format('LL dddd a h:mm')} ~{' '}
            {moment(end_datetime * 1000).format('a h:mm')}
          </TableRow>
          <TableRow title="대관장소">
            {spaces.map(space => {
              return <Label key={space.id} content={space.name} />;
            })}
          </TableRow>
          <TableRow title="대관유형">
            <Label content={category_ko} color={category_color} />
          </TableRow>
        </Table.Body>
      </Table>
      <Table basic="very" className="mt-2" unstackable>
        {tableTitle('신청자 정보')}
        <Table.Body>
          <TableRow title="이름">{name}</TableRow>
          <TableRow title="주최기관">{institution}</TableRow>
          <TableRow title="이메일">{email}</TableRow>
          <TableRow title="연락처">{phone}</TableRow>
        </Table.Body>
      </Table>
      <Table basic="very" className="mt-2" unstackable>
        {tableTitle('모임 정보')}
        <Table.Body>
          <TableRow title="모임명">{title}</TableRow>
          <TableRow title="참석자 수">{number_of_participants}명</TableRow>
          <TableRow title="대관목적">{purpose_ko}</TableRow>
          <TableRow title="공개여부">{is_public ? '공개' : '비공개'}</TableRow>
          <TableRow title="유/무료 여부">{cost ? `유료 (${cost}원)` : '무료'}</TableRow>
          <TableRow title="필요 장비">
            <Fragment>
              {microphone && <Label content="마이크" />}
              {projector && <Label content="프로젝터" />}
              {sound_equipment && <Label content="음향" />}
              <p className="mt-h">{equipment_request}</p>
              {!microphone && !projector && !sound_equipment && !equipment_request && '없음'}
            </Fragment>
          </TableRow>
          <TableRow title="케이터링">{valueText(catering)}</TableRow>
          <TableRow title="현수막/포스터 부착">{valueText(banner)}</TableRow>
          <TableRow title="케이터링">{valueText(catering)}</TableRow>
        </Table.Body>
      </Table>
      <Table basic="very" className="mt-2" unstackable>
        {tableTitle('모임 상세정보')}
        <Table.Body>
          <TableRow title="설명">
            <SplitContent content={description} />
          </TableRow>
          {agenda_url && (
            <TableRow title="아젠다">
              <a href={agenda_url} target="_blank" rel="noopener noreferrer">
                {agenda_filename}
              </a>
            </TableRow>
          )}
          <TableRow title="홍보 유무">{valueText(promotion)}</TableRow>
          <TableRow title="행사정보 링크">{website}</TableRow>
          <TableRow title="약관동의 여부">{valueText(agreed_to_terms)}</TableRow>
        </Table.Body>
      </Table>
      <Table basic="very" className="mt-2" unstackable>
        <Table.Body>
          <TableRow title="비고">
            <Mutation mutation={AdminUpdateReservationMutation}>
              {adminUpdateReservation => {
                return (
                  <EditableLabel
                    blankText="없음"
                    text={comment}
                    onFocusOut={text => {
                      if (comment !== text)
                        adminUpdateReservation({
                          variables: {
                            id: reservation.id,
                            comment: text,
                          },
                        }).then(response => {
                          this.setState({
                            comment: response.data.adminUpdateReservation.comment,
                          });
                        });
                    }}
                  />
                );
              }}
            </Mutation>
          </TableRow>
        </Table.Body>
      </Table>
      <Button as={Link} to={`/reservations/${id}/edit`} size="large" content="수정" primary />
      <Button as={Link} to="reservations/general/all" size="large" content="목록" secondary />
    </Container>
  );
}

Reservation.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
};

export default Reservation;
