import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as qs from 'query-string';
import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Container, Input, Loader, Pagination, Table } from 'semantic-ui-react';
import { EventsCountQuery, EventsQuery } from '../../graphql/events';
import { Time } from '../commons';
import EventTab from './EventTab';

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputText: '',
      query: '',
    };
    this.updateQuery = _.debounce(this.updateQuery, 300);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  updateQuery = () => {
    const { history } = this.props;
    this.setState({
      query: this.state.inputText,
    });
    history.push('/events');
  };

  handleKeyDown = e => {
    const { history } = this.props;
    const params = qs.parse(history.location.search);
    const page = params.page ? Number(params.page) : 1;

    switch (e.keyCode) {
      case 37:
        if (page > 1) {
          history.push(`/events/?page=${page - 1}`);
        }
        break;
      case 39:
        history.push(`/events/?page=${page + 1}`);
        break;
      default:
        break;
    }
  };

  render() {
    const { history, match } = this.props;
    const params = qs.parse(history.location.search);
    const page = params.page ? Number(params.page) : 1;
    const { inputText, query } = this.state;
    const { institution } = match.params;

    return (
      <Container fluid className="pb-3">
        <EventTab />
        <Input
          className="mt-1"
          fluid
          focus
          icon="search"
          onChange={e => {
            this.setState({ inputText: e.target.value });
            this.updateQuery();
          }}
          placeholder="검색하기"
          value={inputText}
        />
        <Query
          query={EventsQuery}
          variables={{
            offset: 20 * (page - 1),
            query,
            institution,
          }}
        >
          {({ loading, data }) => {
            if (loading) {
              return <Loader active inline="centered" className="mv-3" />;
            }
            if (!data || !data.events) return null;
            const { events } = data;

            return (
              <Fragment>
                <Table celled selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>ID</Table.HeaderCell>
                      <Table.HeaderCell>이벤트 이름</Table.HeaderCell>
                      <Table.HeaderCell>이벤트 일시</Table.HeaderCell>
                      <Table.HeaderCell>이름</Table.HeaderCell>
                      <Table.HeaderCell>장소</Table.HeaderCell>
                      <Table.HeaderCell>생성일</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {events.map(event => {
                      const {
                        id,
                        title,
                        name,
                        space,
                        created_at,
                        start_datetime,
                        end_datetime,
                      } = event;
                      return (
                        <Table.Row
                          key={id}
                          onClick={() => this.props.history.push(`/events/${id}`)}
                        >
                          <Table.Cell>{id}</Table.Cell>
                          <Table.Cell>{title}</Table.Cell>
                          <Table.Cell>
                            {moment(start_datetime * 1000).format('Y/M/D H:mm')}~{' '}
                            {moment(end_datetime * 1000).format('H:mm')}
                          </Table.Cell>
                          <Table.Cell>{name}</Table.Cell>
                          <Table.Cell>{space && space.name}</Table.Cell>
                          <Table.Cell>
                            <Time unix={created_at} format="LL" />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
                <Query query={EventsCountQuery} variables={{ query, institution }}>
                  {({ data: eventsCountData }) => {
                    if (!eventsCountData || !eventsCountData.eventsCount) return null;
                    const { eventsCount } = eventsCountData;
                    if (eventsCount < 20) return null;
                    return (
                      <Pagination
                        activePage={page}
                        totalPages={eventsCount ? Math.ceil(eventsCount / 20) : 100}
                        onPageChange={(_e, { activePage }) => {
                          this.props.history.push(`/events/?page=${activePage}`);
                        }}
                      />
                    );
                  }}
                </Query>
              </Fragment>
            );
          }}
        </Query>
      </Container>
    );
  }
}

Events.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }),
};

export default withRouter(Events);
