import gql from 'graphql-tag';

const SendReservationApprovalMail = gql`
  mutation sendReservationApprovalMail($id: ID!) {
    sendReservationApprovalMail(id: $id) {
      id
      approval_mail_sent
    }
  }
`;

export default SendReservationApprovalMail;
