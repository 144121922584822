import React, { Component } from 'react';
import { Container, Grid, Button, Divider, Form } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import { AdminLoginMutation } from '../graphql/public';
import { withStatus } from '../StatusContext';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    };
  }

  onLoginSuccess = response => {
    const { token, currentAdmin } = response.data.adminLogin;
    localStorage.setItem('token', token);
    localStorage.setItem('adminId', currentAdmin.id);
    this.props.status.login();
  };

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Ooops!</strong> {this.props.errorMessage}
        </div>
      );
    }
  }

  render() {
    const { email, password } = this.state;
    return (
      <Mutation mutation={AdminLoginMutation}>
        {adminLogin => (
          <Container text textAlign="center" style={{ marginTop: '3.5em' }}>
            <Grid>
              <Grid.Column mobile={16} tablet={4} computer={4} />
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <Form
                  onSubmit={() => {
                    if (!email) {
                      alert('이메일을 입력해주세요.');
                    } else if (!password) {
                      alert('비밀번호를 입력해주세요.');
                    } else {
                      adminLogin({ variables: { email, password } })
                        .then(response => this.onLoginSuccess(response))
                        .catch(error => {
                          if (
                            error.graphQLErrors &&
                            error.graphQLErrors.length > 0
                          ) {
                            alert(error.graphQLErrors[0].message);
                          }
                        });
                    }
                  }}
                >
                  <Divider hidden />
                  <Form.Input
                    autoFocus
                    placeholder="이메일"
                    value={email}
                    onChange={event =>
                      this.setState({ email: event.target.value })
                    }
                  />
                  <Form.Input
                    placeholder="비밀번호"
                    value={password}
                    type="password"
                    onChange={event =>
                      this.setState({ password: event.target.value })
                    }
                  />
                  <Button
                    content="로그인"
                    fluid
                    primary
                    size="large"
                    type="submit"
                  />
                </Form>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={4} computer={4} />
            </Grid>
          </Container>
        )}
      </Mutation>
    );
  }
}

export default withStatus(Login);
