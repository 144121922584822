import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';
import { StoryForm } from '.';

export const UPDATE_STORY = gql`
  mutation adminUpdateStory($id: ID!, $title: String, $content: String, $coverSignedId: String) {
    adminUpdateStory(
      id: $id
      story: { title: $title, content: $content }
      cover_signed_id: $coverSignedId
    ) {
      id
      title
      content
      cover_url
      cover_filename
      updated_at
    }
  }
`;

export const GET_STORY = gql`
  query Story($id: ID!) {
    story(id: $id) {
      id
      title
      content
      cover_url
      cover_filename
      created_at
      updated_at
    }
  }
`;

function EditStory({ match }) {
  const id = Number(match.params.id);
  const { loading, error, data } = useQuery(GET_STORY, {
    variables: { id },
  });
  const [adminUpdateStory, { updateLoading }] = useMutation(UPDATE_STORY);
  if (loading) {
    return (
      <Dimmer active inverted>
        <Loader active inline="centered" />
      </Dimmer>
    );
  }
  if (error) return null;
  if (!data || !data.story) return null;
  const { story } = data;
  return <StoryForm story={story} submit={adminUpdateStory} loading={updateLoading} />;
}

EditStory.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
};

export default EditStory;
