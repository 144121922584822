import gql from 'graphql-tag';

const AdminReservationsQuery = gql`
  query AdminReservations(
    $offset: Int
    $limit: Int
    $status: String
    $query: String
  ) {
    adminReservations(
      offset: $offset
      limit: $limit
      status: $status
      query: $query
    ) {
      id
      status
      status_ko
      status_color
      start_datetime
      end_datetime
      space_ids
      spaces {
        id
        name
      }
      category
      category_ko
      name
      institution
      email
      phone
      title
      number_of_participants
      purpose
      purpose_ko
      is_public
      cost
      microphone
      projector
      sound_equipment
      equipment_request
      catering
      banner
      description
      promotion
      website
      agreed_to_terms
      comment
      approval_mail_sent
      rejection_mail_sent
      created_at
    }
  }
`;

export default AdminReservationsQuery;
