import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';
import { Header, Table } from 'semantic-ui-react';
import { ReservationHoursByCategoryQuery } from '../../graphql/reservations';

function ReservationHoursByCategory({ from, to }) {
  const { loading, data } = useQuery(ReservationHoursByCategoryQuery, {
    variables: { from, to },
  });
  if (loading) return null;
  if (!data || !data.reservationHoursByCategory) return null;
  const { reservationHoursByCategory } = data;
  const entries = JSON.parse(reservationHoursByCategory);
  if (entries.length < 1) return null;
  const labels = [];
  const counts = [];
  entries.map(([key, value]) => {
    if (key) {
      labels.push(key);
    } else {
      labels.push('기타');
    }
    counts.push(value);
    return null;
  });
  const totalHours = counts.reduce((a, b) => a + b);
  const countsData = {
    labels,
    datasets: [
      {
        data: counts,
        backgroundColor: [
          '#ff6384', // red
          '#907aff', // violet
          '#36a2eb', // blue
          '#4bc0c0', // green
          '#ffce56', // yellow
        ],
      },
    ],
  };
  return (
    <>
      <Header as="h2" content="프로그램별 실제 이용 비율" textAlign="center" className="mt-4" />

      <Pie data={countsData} />
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>프로그램 구분</Table.HeaderCell>
            <Table.HeaderCell>시간</Table.HeaderCell>
            <Table.HeaderCell>비중</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {entries.map(([key, value]) => (
            <Table.Row key={key}>
              <Table.Cell>{key || '기타'}</Table.Cell>
              <Table.Cell>{value}</Table.Cell>
              <Table.Cell>{Math.round((value / totalHours) * 100)}%</Table.Cell>
            </Table.Row>
          ))}
          <Table.Row>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell>{totalHours}</Table.Cell>
            <Table.Cell>100%</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}

ReservationHoursByCategory.propTypes = {
  from: PropTypes.number,
  to: PropTypes.number,
};

export default ReservationHoursByCategory;
