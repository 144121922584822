import gql from 'graphql-tag';

const ExportCSVMutation = gql`
  mutation exportCSV($from: Int, $to: Int) {
    exportCSV(from: $from, to: $to) {
      status
    }
  }
`;

export default ExportCSVMutation;
