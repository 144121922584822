import gql from 'graphql-tag';

const SendReservationRejectionMail = gql`
  mutation sendReservationRejectionMail($id: ID!) {
    sendReservationRejectionMail(id: $id) {
      id
      rejection_mail_sent
    }
  }
`;

export default SendReservationRejectionMail;
