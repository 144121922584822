import gql from 'graphql-tag';

const UserQuery = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      name
      birthday
      category
      category_ko
      company
      phone
      email
      bio
      created_at
    }
  }
`;
export default UserQuery;
