import React, { Component } from 'react';
import Login from './components/Login';
import Main from './components/Main';
import { StatusContext } from './StatusContext';

class App extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem('token');

    this.logout = () => {
      this.setState({ authenticated: false });
    };

    this.login = () => {
      this.setState({ authenticated: true });
    };

    this.state = {
      authenticated: token ? true : false,
      logout: this.logout,
      login: this.login
    };
  }

  render() {
    const { authenticated } = this.state;
    return (
      <StatusContext.Provider value={this.state}>
        {authenticated ? <Main /> : <Login />}
      </StatusContext.Provider>
    );
  }
}

export default App;
