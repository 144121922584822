import _ from 'lodash';
import PropTypes from 'prop-types';
import * as qs from 'query-string';
import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Container, Input, Loader, Pagination, Table } from 'semantic-ui-react';
import { UsersCountQuery, UsersQuery } from '../../graphql/users';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputText: '',
      query: '',
    };
    this.updateQuery = _.debounce(this.updateQuery, 300);
  }

  handleKeyDown = event => {
    const { history } = this.props;
    const params = qs.parse(history.location.search);
    const page = params.page ? Number(params.page) : 1;

    switch (event.keyCode) {
      case 37:
        if (page > 1) {
          history.push(`/users/?page=${page - 1}`);
        }
        break;
      case 39:
        history.push(`/users/?page=${page + 1}`);
        break;
      default:
        break;
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  updateQuery = () => {
    const { history } = this.props;
    this.setState({
      query: this.state.inputText,
    });
    history.push('/users');
  };

  render() {
    const params = qs.parse(this.props.location.search);
    const page = params.page ? Number(params.page) : 1;
    const { inputText, query } = this.state;

    return (
      <Container fluid className="pb-3">
        <Input
          fluid
          focus
          icon="search"
          onChange={e => {
            const inputText = e.target.value;
            this.setState({ inputText });
            this.updateQuery();
          }}
          placeholder="검색하기"
          value={inputText}
        />
        <Query
          query={UsersQuery}
          variables={{
            offset: 20 * (page - 1),
            query,
          }}
        >
          {({ loading, data }) => {
            if (loading) {
              return <Loader active inline="centered" className="mv-3" />;
            }
            if (!data || !data.users) return null;
            const { users } = data;
            return (
              <Fragment>
                <Table celled selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>ID</Table.HeaderCell>
                      <Table.HeaderCell>이름</Table.HeaderCell>
                      <Table.HeaderCell>기관명</Table.HeaderCell>
                      <Table.HeaderCell>전화번호</Table.HeaderCell>
                      <Table.HeaderCell>이메일</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {users.map(user => (
                      <Table.Row
                        key={user.id}
                        onClick={() => this.props.history.push(`/users/${user.id}`)}
                      >
                        <Table.Cell>{user.id}</Table.Cell>
                        <Table.Cell>{user.name}</Table.Cell>
                        <Table.Cell>{user.company}</Table.Cell>
                        <Table.Cell>{user.phone}</Table.Cell>
                        <Table.Cell>{user.email}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                <Query query={UsersCountQuery} variables={{ query }}>
                  {({ data: usersCountData }) => {
                    if (!usersCountData || !usersCountData.usersCount) return null;
                    const { usersCount } = data;
                    if (usersCount < 20) return null;
                    return (
                      <Pagination
                        activePage={page}
                        totalPages={usersCount ? Math.ceil(usersCount / 20) : 100}
                        onPageChange={(_, { activePage }) => {
                          this.props.history.push(`/users/?page=${activePage}`);
                        }}
                      />
                    );
                  }}
                </Query>
              </Fragment>
            );
          }}
        </Query>
      </Container>
    );
  }
}

Users.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export default withRouter(Users);
