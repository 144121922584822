import React, { useEffect, useState } from 'react';
import { Button, Container, Dimmer, Form, Grid, Header, Loader } from 'semantic-ui-react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';
import EventQuery from '../../graphql/events/EventQuery';

const UPDATE_EVENT_MUTATION = gql`
  mutation UpdateEventMutation(
    $id: ID!
    $startDatetime: Int
    $endDatetime: Int
    $name: String
    $institution: String
    $email: String
    $phone: String
    $title: String
    $numberOfParticipants: Int
  ) {
    adminUpdateEvent(
      id: $id
      event: {
        start_datetime: $startDatetime
        end_datetime: $endDatetime
        name: $name
        institution: $institution
        email: $email
        phone: $phone
        title: $title
        number_of_participants: $numberOfParticipants
      }
    ) {
      id
      start_datetime
      end_datetime
      name
      institution
      email
      phone
      title
      number_of_participants
    }
  }
`;

const DELETE_EVENT_MUTATION = gql`
  mutation DeleteEventMutation($id: ID!) {
    adminDeleteEvent(id: $id) {
      id
    }
  }
`;

export default function EditEvent() {
  const { id } = useParams();
  const { push } = useHistory();
  const [date, setDate] = useState(undefined);
  const [startTime, setStartTime] = useState(undefined);
  const [endTime, setEndTime] = useState(undefined);
  const [name, setName] = useState('');
  const [institution, setInstitution] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [title, setTitle] = useState('');
  const [numberOfParticipants, setNumberOfParticipants] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const { loading, data } = useQuery(EventQuery, {
    variables: { id },
    onCompleted: ({ event }) => {
      if (event) {
        setDate(moment(event.start_datetime * 1000).toDate());
        setStartTime(moment(event.start_datetime * 1000));
        setEndTime(moment(event.end_datetime * 1000));
        setName(event.name);
        setInstitution(event.institution);
        setEmail(event.email);
        setPhone(event.phone);
        setTitle(event.title);
        setNumberOfParticipants(event.number_of_participants.toString());
      }
    },
  });
  const [adminUpdateEvent, { loading: updateLoading }] = useMutation(UPDATE_EVENT_MUTATION, {
    onCompleted: ({ adminUpdateEvent }) => {
      if (adminUpdateEvent) {
        setSubmitted(true);
        push(`/events/${adminUpdateEvent.id}`);
      }
    },
    onError: ({ graphQLErrors }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message }) => {
          alert(message);
          return null;
        });
      }
    },
  });

  const [adminDeleteEvent, { loading: deleteLoading }] = useMutation(DELETE_EVENT_MUTATION, {
    variables: { id },
    onCompleted: ({ adminDeleteEvent }) => {
      if (adminDeleteEvent) {
        setSubmitted(true);
        push('/events');
      }
    },
    onError: ({ graphQLErrors }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message }) => {
          alert(message);
          return null;
        });
      }
    },
  });

  useEffect(() => {
    const handler = e => {
      e.returnValue = true;
    };
    window.addEventListener('beforeunload', handler);
    return () => window.removeEventListener('beforeunload', handler);
  });

  if (loading)
    return (
      <Dimmer active inverted>
        <Loader active inline="centered" />
      </Dimmer>
    );
  if (!data || !data.event) return null;
  const today = moment(Date.now());
  if (moment(data.event.start_datetime * 1000).isBefore(today)) {
    push('/');
  }

  let disabledStartHours = () => {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 22, 23];
  };
  let disabledEndHours = () => {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 23];
  };
  if (date && date.getDay() === 6) {
    disabledStartHours = () => {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 20, 21, 22, 23];
    };
    disabledEndHours = () => {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 21, 22, 23];
    };
  }

  const onSubmit = () => {
    if (!date) {
      alert('날짜를 입력해주세요.');
      return;
    }
    if (!startTime) {
      alert('시작 시간을 입력해주세요.');
      return;
    }
    if (!endTime) {
      alert('종료 시간을 입력해주세요.');
      return;
    }
    if (!email) {
      alert('이메일을 입력해주세요.');
      return;
    }
    if (!phone) {
      alert('연락처를 입력해주세요.');
      return;
    }
    if (!title) {
      alert('모임명을 입력해주세요.');
      return;
    }
    if (!numberOfParticipants) {
      alert('참석자 수를 입력해주세요.');
      return;
    }
    const startDatetime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      startTime.get('hour'),
      startTime.get('minute'),
      0,
    );
    const endDatetime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      endTime.get('hour'),
      endTime.get('minute'),
      0,
    );
    const variables = {
      id,
      startDatetime: moment(startDatetime).unix(),
      endDatetime: moment(endDatetime).unix(),
      name,
      institution,
      email,
      phone,
      title,
      numberOfParticipants: Number(numberOfParticipants),
    };
    adminUpdateEvent({ variables });
  };

  return (
    <Container text className="pv-3">
      <Grid>
        <Grid.Row>
          <Grid.Column only="tablet computer" tablet={3} computer={3} largeScreen={3} />
          <Grid.Column mobile={16} tablet={10} computer={10} largeScreen={10}>
            <Form onSubmit={onSubmit} size="large">
              <Header as="h2" content="소모임 기본정보" className="ta-c" />
              <Form.Input
                required
                fluid
                label="모임명"
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
              <Form.Input
                required
                fluid
                label="참석자 수"
                type="number"
                value={numberOfParticipants}
                onChange={e => setNumberOfParticipants(e.target.value)}
              />
              <Form.Field required>
                <label htmlFor="eventDate">모임 날짜</label>
                <DayPickerInput
                  inputProps={{ readOnly: true }}
                  value={date}
                  dayPickerProps={{
                    localeUtils: MomentLocaleUtils,
                    locale: 'ko',
                    disabledDays: [
                      { daysOfWeek: [0] },
                      {
                        before: moment().toDate(),
                        after: moment()
                          .add(2, 'M')
                          .toDate(),
                      },
                    ],
                  }}
                  placeholder="YYYY-MM-DD"
                  onDayChange={d => {
                    setDate(d);
                    setStartTime(null);
                    setEndTime(null);
                  }}
                />
              </Form.Field>
              <Form.Group widths="equal">
                <Form.Field required>
                  <label htmlFor="startTime">시작 시간</label>
                  <TimePicker
                    id="startTime"
                    value={startTime}
                    minuteStep={30}
                    showSecond={false}
                    onChange={value => {
                      setStartTime(value);
                    }}
                    inputReadOnly
                    disabledHours={disabledStartHours}
                    hideDisabledOptions
                  />
                </Form.Field>
                <Form.Field required>
                  <label htmlFor="endTime">종료 시간</label>
                  <TimePicker
                    id="endTime"
                    value={endTime}
                    minuteStep={30}
                    showSecond={false}
                    onChange={value => setEndTime(value)}
                    inputReadOnly
                    disabledHours={disabledEndHours}
                    hideDisabledOptions
                  />
                </Form.Field>
              </Form.Group>
              <Header as="h2" content="신청자 정보" textAlign="center" className="mt-4" />
              <Form.Input
                required
                fluid
                label="이름"
                placeholder="홍길동"
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <Form.Input
                required
                fluid
                label="기관명"
                value={institution}
                onChange={e => setInstitution(e.target.value)}
              />
              <Form.Input
                required
                fluid
                label="이메일"
                placeholder="yourname@email.com"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <Form.Input
                required
                fluid
                label="연락처"
                placeholder="010-1234-5678"
                value={phone}
                onChange={e => setPhone(e.target.value)}
              />

              <Button
                size="large"
                primary
                content="저장하기"
                type="submit"
                loading={updateLoading}
                disabled={updateLoading}
              />
              <Button
                floated="right"
                basic
                primary
                onClick={e => {
                  e.preventDefault();
                  if (window.confirm('정말로 이벤트를 삭제하시겠습니까?')) adminDeleteEvent();
                }}
                size="large"
                content="삭제"
                className="default"
                loading={deleteLoading}
                disabled={deleteLoading}
              />
              <Prompt
                when={!submitted}
                message="정말 이 페이지를 나가시겠습니까? 변경사항이 저장되지 않을 수 있습니다."
              />
            </Form>
          </Grid.Column>
          <Grid.Column only="tablet computer" tablet={3} computer={3} largeScreen={3} />
        </Grid.Row>
      </Grid>
    </Container>
  );
}
