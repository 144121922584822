import gql from 'graphql-tag';

const ReservationQuery = gql`
  query Reservation($id: ID!) {
    reservation(id: $id) {
      id
      status
      status_ko
      status_color
      start_datetime
      end_datetime
      created_at
      space_ids
      spaces {
        id
        name
      }
      category
      category_ko
      category_color
      name
      institution
      email
      phone
      title
      number_of_participants
      purpose
      purpose_ko
      is_public
      cost
      microphone
      projector
      sound_equipment
      equipment_request
      catering
      banner
      description
      promotion
      website
      agenda_url
      agenda_filename
      agreed_to_terms
      comment
      approval_mail_sent
      rejection_mail_sent
    }
  }
`;

export default ReservationQuery;
