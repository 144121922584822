import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Card, Container, Loader, Table } from 'semantic-ui-react';
import { UserReservations } from '.';
import { UserQuery } from '../../graphql/users';
import { TableRow, Time } from '../commons';

function User({ match }) {
  const { id } = match.params;
  const { loading, data } = useQuery(UserQuery, {
    variables: { id },
  });
  if (loading) {
    return <Loader active inline="centered" className="mv-3" />;
  }
  if (!data || !data.user) return null;
  const { user } = data;
  const { name, category_ko, company, phone, email, bio, created_at } = user;
  return (
    <Container fluid>
      <Card fluid>
        <Card.Content>
          <Card.Header>
            {name} ({id})
          </Card.Header>

          <Table basic="very" unstackable>
            <Table.Body>
              <TableRow title="ID">{id}</TableRow>
              <TableRow title="생성일">
                <Time unix={created_at} format="LL" />
              </TableRow>
              <TableRow title="연락처">{phone}</TableRow>
              <TableRow title="이메일">{email}</TableRow>
              <TableRow title="소속분야">{category_ko}</TableRow>
              <TableRow title="기관명">{company}</TableRow>
              <TableRow title="소개">{bio}</TableRow>
            </Table.Body>
          </Table>
        </Card.Content>
        <Card.Content extra>
          <Button as={Link} to={`/users/${id}/edit`} content="수정" primary />
        </Card.Content>
      </Card>

      <UserReservations userId={id} />
    </Container>
  );
}

User.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
};

export default User;
