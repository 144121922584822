import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ScrollToTop } from './commons';
import Navbar from './Navbar';
import { Reports } from './reports';
import { Event, Events, EditEvent } from './events';
import {
  Calendar,
  Reservations,
  ReservationsDetail,
  Reservation,
  NewReservation,
  EditReservation,
} from './reservations';
import { EditStory, NewStory, Stories, Story } from './stories';
import { Users, User, EditUser } from './users';

const Main = () => (
  <Router>
    <ScrollToTop>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Calendar} />
        <Route exact path="/reports" component={Reports} />
        <Route exact path="/reservations/general/:status" component={Reservations} />
        <Route exact path="/reservations/detail/:status" component={ReservationsDetail} />
        <Route exact path="/reservations/new" component={NewReservation} />
        <Route exact path="/reservations/:id" component={Reservation} />
        <Route exact path="/reservations/:id/edit" component={EditReservation} />
        <Route exact path="/stories" component={Stories} />
        <Route exact path="/stories/new" component={NewStory} />
        <Route exact path="/stories/:id" component={Story} />
        <Route exact path="/stories/:id/edit" component={EditStory} />
        <Route exact path="/users" component={Users} />
        <Route exact path="/users/:id" component={User} />
        <Route exact path="/users/:id/edit" component={EditUser} />
        <Route exact path="/events" component={Events} />
        <Route exact path="/events/institution/:institution" component={Events} />
        <Route exact path="/events/:id" component={Event} />
        <Route exact path="/events/:id/edit" component={EditEvent} />
      </Switch>
    </ScrollToTop>
  </Router>
);

export default Main;
