import React from 'react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

const ReservationStatusTab = ({ detail, history }) => {
  const detailText = detail ? 'reservations/detail' : 'reservations/general';
  return (
    <Button.Group floated="right">
      <Button
        as={Link}
        to={`/${detailText}/all`}
        active={history.location.pathname.includes('/all')}
        content="전체"
      />
      <Button
        as={Link}
        to={`/${detailText}/pending`}
        active={history.location.pathname.includes('/pending')}
        content="접수중"
      />
      <Button
        as={Link}
        to={`/${detailText}/approved`}
        active={history.location.pathname.includes('/approved')}
        content="승인됨"
      />
      <Button
        as={Link}
        to={`/${detailText}/rejected`}
        active={history.location.pathname.includes('/rejected')}
        content="거절됨"
      />
      <Button
        as={Link}
        to={`/${detailText}/cancelled`}
        active={history.location.pathname.includes('/cancelled')}
        content="취소됨"
      />
    </Button.Group>
  );
};

export default withRouter(ReservationStatusTab);
