import React, { Component } from 'react';
import {
  // Button,
  Container,
  Divider,
  Form,
  Header,
} from 'semantic-ui-react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment';
import {
  ReservationHoursByCategory,
  ReservationHoursByInstitution,
  ReservationStats,
  // ExportCSVButton,
} from '.';

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // date: moment(),
      startDate: '',
      endDate: '',
    };
  }

  render() {
    const {
      startDate,
      endDate,
      // date
    } = this.state;
    const from = startDate ? moment(startDate).unix() : null;
    const to = endDate ? moment(endDate).unix() : null;
    return (
      <Container fluid className="pb-3">
        <Form size="large" className="ta-l">
          <Form.Group widths="equal">
            <Form.Field className="mb-0">
              <DayPickerInput
                value={startDate}
                dayPickerProps={{
                  localeUtils: MomentLocaleUtils,
                  locale: 'ko',
                }}
                placeholder="시작일"
                onDayChange={d => this.setState({ startDate: d })}
              />
            </Form.Field>
            <Form.Field className="mb-0">
              <DayPickerInput
                value={endDate}
                dayPickerProps={{
                  localeUtils: MomentLocaleUtils,
                  locale: 'ko',
                }}
                placeholder="종료일"
                onDayChange={d => this.setState({ endDate: d })}
              />
            </Form.Field>
            {/* <ExportCSVButton from={from} to={to} /> */}
          </Form.Group>
        </Form>

        {/* <Button.Group>
          <Button
            icon="left chevron"
            onClick={() => this.setState({ date: date.add(-1, 'year') })}
          />
          <Button>오늘</Button>
          <Button
            icon="right chevron"
            onClick={() => this.setState({ date: date.add(1, 'year') })}
          />
        </Button.Group> */}
        <Header as="h1" textAlign="center" className="mv-5">
          체인지메이커스 운영 리포트
          {startDate && endDate && (
            <Header.Subheader>
              {moment(startDate).format('LL')} ~ {moment(endDate).format('LL')}
            </Header.Subheader>
          )}
        </Header>
        <Divider section />
        <ReservationStats from={from} to={to} />
        <Divider section />
        <ReservationHoursByCategory from={from} to={to} />
        <ReservationHoursByInstitution
          from={from}
          to={to}
          category="heyground"
          categoryKo="헤이그라운드"
        />
        <ReservationHoursByInstitution
          from={from}
          to={to}
          category="team_of_teams"
          categoryKo="팀 오브 팀스"
        />
        <ReservationHoursByInstitution
          from={from}
          to={to}
          category="signature"
          categoryKo="시그니처"
        />
      </Container>
    );
  }
}

export default Reports;
