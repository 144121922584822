import gql from 'graphql-tag';

const AdminUpdateReservationMutation = gql`
  mutation adminUpdateReservation(
    $id: ID!
    $space_ids: [Int]
    $start_datetime: Int
    $end_datetime: Int
    $category: String
    $name: String
    $institution: String
    $email: String
    $phone: String
    $title: String
    $description: String
    $number_of_participants: Int
    $purpose: String
    $is_public: Boolean
    $cost: Int
    $microphone: Boolean
    $projector: Boolean
    $sound_equipment: Boolean
    $equipment_request: String
    $catering: Boolean
    $banner: Boolean
    $promotion: Boolean
    $website: String
    $status: String
    $comment: String
    $agenda_signed_id: String
  ) {
    adminUpdateReservation(
      id: $id
      agenda_signed_id: $agenda_signed_id
      reservation: {
        space_ids: $space_ids
        start_datetime: $start_datetime
        end_datetime: $end_datetime
        category: $category
        name: $name
        institution: $institution
        email: $email
        phone: $phone
        title: $title
        description: $description
        number_of_participants: $number_of_participants
        purpose: $purpose
        is_public: $is_public
        cost: $cost
        microphone: $microphone
        projector: $projector
        sound_equipment: $sound_equipment
        equipment_request: $equipment_request
        catering: $catering
        banner: $banner
        promotion: $promotion
        website: $website
        status: $status
        comment: $comment
      }
    ) {
      id
      status
      status_ko
      status_color
      start_datetime
      end_datetime
      space_ids
      spaces {
        id
        name
      }
      category
      category_ko
      name
      institution
      email
      phone
      title
      number_of_participants
      purpose
      purpose_ko
      is_public
      cost
      microphone
      projector
      sound_equipment
      equipment_request
      catering
      banner
      description
      promotion
      website
      comment
      agenda_url
      agenda_filename
    }
  }
`;

export default AdminUpdateReservationMutation;
