import gql from 'graphql-tag';

const PurgeReservationAgendaMutation = gql`
  mutation adminPurgeReservationAgenda($id: ID!) {
    adminPurgeReservationAgenda(id: $id) {
      id
      agenda_url
      agenda_filename
    }
  }
`;

export default PurgeReservationAgendaMutation;
