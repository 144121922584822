import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import { DirectUploadProvider } from 'react-activestorage-provider';
import { Button, Form, Icon, Image, Label, Progress } from 'semantic-ui-react';
import Globals from '../../constants/Globals';

const PURGE_STORY_COVER = gql`
  mutation adminPurgeStoryCover($id: ID!) {
    adminPurgeStoryCover(id: $id) {
      id
      cover_url
      cover_filename
    }
  }
`;

function StoryCoverUploader({
  id,
  coverUrl,
  fileName,
  setCoverUrl,
  setFileName,
  setCoverSignedId,
}) {
  const [adminPurgeStoryCover, { loading }] = useMutation(PURGE_STORY_COVER);
  return (
    <>
      <Image src={coverUrl} fluid />
      <Form.Group grouped className="mt-1 mb-q">
        <DirectUploadProvider
          directUploadsPath={`${Globals.BASE_URL}/rails/active_storage/direct_uploads`}
          onSuccess={signedIds => {
            setCoverSignedId(signedIds[0]);
          }}
          render={({ handleUpload, uploads, ready }) => {
            return (
              <div>
                <Button primary as="label" htmlFor="file" className="mt-q" disabled={!!fileName}>
                  <Icon name="upload" />
                  커버 이미지 업로드
                </Button>
                {fileName ? (
                  <Label.Group>
                    <Label className="mt-h" size="small">
                      {fileName}
                      <Icon
                        name="delete"
                        loading={id && loading}
                        onClick={() => {
                          if (id) {
                            adminPurgeStoryCover({
                              variables: { id },
                            });
                          }
                          setCoverSignedId(null);
                          setFileName('');
                          setCoverUrl('');
                        }}
                      />
                    </Label>
                  </Label.Group>
                ) : (
                  <p className="mt-h small gray">
                    ※ 아래 형식에 해당되는 파일을 첨부해주세요. <br />
                    (jpg, png, gif)
                  </p>
                )}
                <input
                  hidden
                  id="file"
                  type="file"
                  disabled={!ready}
                  accept=".jpg, .jpeg, .png, .gif"
                  onChange={e => {
                    handleUpload(e.currentTarget.files);
                    if (e.target.files.length > 0) {
                      const file = e.target.files[0];
                      setFileName(file.name);
                      console.log(file);
                    }
                    e.target.value = null;
                  }}
                />

                {uploads.map(upload => {
                  switch (upload.state) {
                    case 'waiting':
                      return (
                        <p className="mt-h small" key={upload.id}>
                          업로드 대기중
                        </p>
                      );
                    case 'uploading':
                      return (
                        <Progress
                          label="업로드 중"
                          size="small"
                          color="blue"
                          key={upload.id}
                          percent={upload.progress}
                          progress
                        />
                      );
                    case 'error':
                      return (
                        <p className="mt-h small" key={upload.id}>
                          {`업로드 실패: ${upload.error}`}
                        </p>
                      );
                    case 'finished':
                      return (
                        <p className="mt-h small" key={upload.id}>
                          {`${upload.file.name} 업로드 완료`}
                        </p>
                      );
                    default:
                      return null;
                  }
                })}
              </div>
            );
          }}
        />
      </Form.Group>
    </>
  );
}

StoryCoverUploader.propTypes = {
  id: PropTypes.number,
  coverUrl: PropTypes.string,
  fileName: PropTypes.string,
  setFileName: PropTypes.func.isRequired,
  setCoverUrl: PropTypes.func.isRequired,
  setCoverSignedId: PropTypes.func.isRequired,
};

export default StoryCoverUploader;
