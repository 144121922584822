import React, { Component } from 'react';
import { Label, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ko';
import {
  ReservationApprovalButtons,
  ApprovalMailButton,
  RejectionMailButton,
  ReservationCancelButton
} from '..';
import { Time } from '../../commons';

export default class ReservationItem extends Component {
  constructor(props) {
    super(props);
    const { status_ko } = props.reservation;
    this.state = {
      selectedStatus: status_ko
    };
  }

  render() {
    const {
      id,
      created_at,
      start_datetime,
      end_datetime,
      spaces,
      title,
      category_ko,
      name,
      status,
      status_ko,
      status_color
    } = this.props.reservation;
    return (
      <Table.Row>
        <Table.Cell>{id}</Table.Cell>
        <Table.Cell>
          <Link to={`/reservations/${id}`}>{title}</Link>
        </Table.Cell>
        <Table.Cell>
          {moment(start_datetime * 1000).format('Y/M/D')}{' '}
          {moment(start_datetime * 1000).format('H:mm')}~{' '}
          {moment(end_datetime * 1000).format('H:mm')}
        </Table.Cell>
        <Table.Cell>{spaces.map(space => space.name).join(', ')}</Table.Cell>
        <Table.Cell>{category_ko}</Table.Cell>
        <Table.Cell>{name}</Table.Cell>
        <Table.Cell>
          <Time unix={created_at} format="YYYY/M/D" />
        </Table.Cell>
        <Table.Cell>
          <Label content={status_ko} color={status_color} />
        </Table.Cell>
        <Table.Cell>
          <ReservationApprovalButtons reservation={this.props.reservation} />
        </Table.Cell>
        <Table.Cell>
          {status === 'approved' && (
            <ApprovalMailButton event={this.props.reservation} />
          )}
          {status === 'rejected' && (
            <RejectionMailButton event={this.props.reservation} />
          )}
        </Table.Cell>
        <Table.Cell>
          <ReservationCancelButton reservation={this.props.reservation} />
        </Table.Cell>
      </Table.Row>
    );
  }
}
