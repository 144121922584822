import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import { Container, Form, Grid } from 'semantic-ui-react';
import { StoryCoverUploader, StoryFormActions } from '.';

function StoryForm({ story = {}, history, submit }) {
  const { id, title: storyTitle, content: storyContent, cover_filename, cover_url } = story;
  const [title, setTitle] = useState(storyTitle || '');
  const [content, setContent] = useState(storyContent || '');
  const [fileName, setFileName] = useState(cover_filename);
  const [coverUrl, setCoverUrl] = useState(cover_url);
  const [coverSignedId, setCoverSignedId] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    window.onbeforeunload = !submitted ? () => true : null;
  });

  function handleSubmit() {
    const hash = {
      id,
      title,
      content,
      coverSignedId,
    };
    submit({ variables: hash })
      .then(response => {
        if (response.data) {
          setSubmitted(true);
          let link;
          if (id) {
            link = `/stories/${response.data.adminUpdateStory.id}`;
          } else {
            link = `/stories/${response.data.adminCreateStory.id}`;
          }
          history.push(link);
        }
      })
      .catch(err => {
        alert(err);
      });
  }
  return (
    <Container className="pv-1" textAlign="center">
      <Grid>
        <Grid.Row>
          <Grid.Column only="tablet computer" tablet={3} computer={3} largeScreen={3} />
          <Grid.Column mobile={16} tablet={10} computer={10} largeScreen={10}>
            <Form onSubmit={handleSubmit} size="large" className="ta-l">
              <StoryCoverUploader
                id={id}
                coverUrl={coverUrl}
                fileName={fileName}
                setCoverUrl={setCoverUrl}
                setFileName={setFileName}
                setCoverSignedId={setCoverSignedId}
              />
              <Form.Input
                required
                label="제목"
                placeholder="제목을 입력해주세요."
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
              <Form.TextArea
                required
                label="내용"
                placeholder="내용을 입력해주세요."
                value={content}
                onChange={e => setContent(e.target.value)}
              />
              <StoryFormActions id={id} title={title} setSubmitted={setSubmitted} />
              <Prompt
                when={!submitted}
                message="정말 이 페이지를 나가시겠습니까? 변경사항이 저장되지 않을 수 있습니다."
              />
            </Form>
          </Grid.Column>
          <Grid.Column only="tablet computer" tablet={3} computer={3} largeScreen={3} />
        </Grid.Row>
      </Grid>
    </Container>
  );
}

StoryForm.propTypes = {
  story: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }),
  submit: PropTypes.func.isRequired,
};

export default withRouter(StoryForm);
