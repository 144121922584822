import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import * as qs from 'query-string';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Loader, Pagination } from 'semantic-ui-react';

export const GET_STORIES_COUNT = gql`
  query StoriesCount {
    storiesCount
  }
`;

function StoriesPagination({ history }) {
  const params = qs.parse(history.location.search);
  const page = params.page ? Number(params.page) : 1;
  const { loading, error, data } = useQuery(GET_STORIES_COUNT);

  if (error) return null;
  if (loading) return <Loader active inline="centered" />;
  if (!data || !data.storiesCount) return null;
  const { storiesCount } = data;
  if (storiesCount < 20) return null;
  return (
    <Pagination
      className="mt-2"
      activePage={page}
      totalPages={storiesCount ? Math.ceil(storiesCount / 20) : 100}
      onPageChange={(_, { activePage }) => {
        history.push(`/stories/?page=${activePage}`);
      }}
    />
  );
}

StoriesPagination.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(StoriesPagination);
