import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import * as qs from 'query-string';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Dimmer, Loader, Table } from 'semantic-ui-react';
import { Time } from '../commons';
import { StoriesPagination } from './';

export const GET_STORIES = gql`
  query Stories($offset: Int, $limit: Int) {
    stories(offset: $offset, limit: $limit) {
      id
      title
      created_at
      updated_at
    }
  }
`;

function Stories({ history }) {
  const params = qs.parse(history.location.search);
  const page = params.page ? Number(params.page) : 1;
  const { loading, error, data } = useQuery(GET_STORIES, {
    variables: { offset: 20 * (page - 1) },
  });
  if (loading)
    return (
      <Dimmer active inverted>
        <Loader active inline="centered" />
      </Dimmer>
    );
  if (error) return null;
  if (!data || !data.stories) return null;
  const { stories } = data;
  return (
    <Container fluid className="pb-3">
      <Button as={Link} to="/stories/new" primary content="스토리 작성하기" />
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>제목</Table.HeaderCell>
            <Table.HeaderCell>생성일</Table.HeaderCell>
            <Table.HeaderCell>수정일</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {stories.map(story => {
            const { id, title, created_at, updated_at } = story;
            return (
              <Table.Row key={id} onClick={() => history.push(`/stories/${story.id}`)}>
                <Table.Cell>{id}</Table.Cell>
                <Table.Cell>{title}</Table.Cell>
                <Table.Cell>
                  <Time unix={created_at} format="LL" />
                </Table.Cell>
                <Table.Cell>
                  <Time unix={updated_at} format="LL" />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <StoriesPagination />
    </Container>
  );
}

Stories.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    push: PropTypes.func.isRequired,
  }),
};

export default Stories;
