import React from 'react';
import { Table } from 'semantic-ui-react';

const ReservationHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>ID</Table.HeaderCell>
        <Table.HeaderCell>모임명</Table.HeaderCell>
        <Table.HeaderCell>대관일시</Table.HeaderCell>
        <Table.HeaderCell>장소</Table.HeaderCell>
        <Table.HeaderCell>카테고리</Table.HeaderCell>
        <Table.HeaderCell>이름</Table.HeaderCell>
        <Table.HeaderCell>작성일</Table.HeaderCell>
        <Table.HeaderCell>승인상태</Table.HeaderCell>
        <Table.HeaderCell>승인여부</Table.HeaderCell>
        <Table.HeaderCell>메일발송</Table.HeaderCell>
        <Table.HeaderCell>대관취소</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

export default ReservationHeader;
