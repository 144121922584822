import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import { Button, Card, Container, Label, Loader, Table } from 'semantic-ui-react';
import { EventQuery } from '../../graphql/events';
import { TableRow, Time } from '../commons';

export default function Event() {
  const { id } = useParams();
  const { loading, data } = useQuery(EventQuery, {
    variables: { id },
  });
  if (loading) {
    return <Loader active inline="centered" className="mv-3" />;
  }
  if (!data || !data.event) return null;
  const { event } = data;
  const {
    title,
    name,
    space,
    created_at,
    start_datetime,
    end_datetime,
    institution,
    email,
    phone,
    number_of_participants,
    by_opencollege,
  } = event;
  const tableTitle = t => {
    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="2" content={t} />
        </Table.Row>
      </Table.Header>
    );
  };

  return (
    <Container fluid>
      <Card fluid>
        <Card.Content>
          <Card.Header>{`${title} (${id})`}</Card.Header>
          <Table basic="very" className="mt-2" unstackable>
            {tableTitle('모임 정보')}
            <Table.Body>
              <TableRow title="모임명">{title}</TableRow>
              {!by_opencollege && <TableRow title="참석자 수">{number_of_participants}명</TableRow>}
              <TableRow title="ID">{id}</TableRow>
              <TableRow title="생성일시">
                <Time unix={created_at} format="LLLL" />
              </TableRow>
              <TableRow title="이벤트 일시">
                {moment(start_datetime * 1000).format('LL dddd a h:mm')} ~{' '}
                {moment(end_datetime * 1000).format('a h:mm')}
              </TableRow>
              <TableRow title="장소">
                <Label content={space.name} />
              </TableRow>
            </Table.Body>
          </Table>
          <Table basic="very" className="mt-2" unstackable>
            {tableTitle('신청자 정보')}
            <Table.Body>
              <TableRow title="이름">{name}</TableRow>
              {!by_opencollege && (
                <>
                  <TableRow title="주최기관">{institution}</TableRow>
                  <TableRow title="이메일">{email}</TableRow>
                  <TableRow title="연락처">{phone}</TableRow>
                </>
              )}
            </Table.Body>
          </Table>
        </Card.Content>
      </Card>
      {!by_opencollege && (
        <Button as={Link} to={`/events/${id}/edit`} size="large" content="수정" primary />
      )}
      <Button as={Link} to="/events" size="large" content="목록" secondary />
    </Container>
  );
}
