import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import { Label, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ko';
import { ReservationApprovalButtons } from '..';
import { EditableLabel, Time } from '../../../components/commons';
import { AdminUpdateReservationMutation } from '../../../graphql/reservations';

export default class ReservationItemDetail extends Component {
  constructor(props) {
    super(props);
    const { comment } = props.event;
    this.state = {
      comment: comment
    };
  }
  render() {
    const {
      id,
      created_at,
      start_datetime,
      end_datetime,
      spaces,
      title,
      category_ko,
      name,
      institution,
      email,
      phone,
      status_ko,
      status_color,
      number_of_participants,
      purpose_ko,
      is_public,
      cost,
      promotion,
      website
    } = this.props.event;
    const { comment } = this.state;
    const valueText = value => {
      return value ? '○' : '✕';
    };
    return (
      <Table.Row>
        <Table.Cell>{id}</Table.Cell>
        <Table.Cell>
          <Link to={`/reservations/${id}`}>{title}</Link>
        </Table.Cell>
        <Table.Cell>
          {moment(start_datetime * 1000).format('Y/M/D H:mm')} ~{' '}
          {moment(end_datetime * 1000).format('H:mm')}
        </Table.Cell>
        <Table.Cell>{spaces.map(space => space.name).join(', ')}</Table.Cell>
        <Table.Cell>{category_ko}</Table.Cell>
        <Table.Cell>{name}</Table.Cell>
        <Table.Cell>{institution}</Table.Cell>
        <Table.Cell>{email}</Table.Cell>
        <Table.Cell>{phone}</Table.Cell>
        <Table.Cell>
          {number_of_participants ? `${number_of_participants}명` : ''}
        </Table.Cell>
        <Table.Cell>{purpose_ko}</Table.Cell>
        <Table.Cell>{is_public ? '공개' : '비공개'}</Table.Cell>
        <Table.Cell>{cost ? '유료' : '무료'}</Table.Cell>
        <Table.Cell>{cost ? `${cost}원` : ''}</Table.Cell>
        <Table.Cell>{valueText(promotion)}</Table.Cell>
        <Table.Cell>{website}</Table.Cell>
        <Table.Cell>
          <Time unix={created_at} format="YYYY/M/D" />
        </Table.Cell>
        <Table.Cell>
          <Label content={status_ko} color={status_color} />
        </Table.Cell>
        <Table.Cell>
          <ReservationApprovalButtons reservation={this.props.event} />
        </Table.Cell>

        <Mutation mutation={AdminUpdateReservationMutation}>
          {adminUpdateReservation => {
            return (
              <Table.Cell textAlign="left">
                <EditableLabel
                  text={comment}
                  onFocusOut={text => {
                    if (comment !== text)
                      adminUpdateReservation({
                        variables: { id, comment: text }
                      }).then(response => {
                        this.setState({
                          comment: response.data.adminUpdateReservation.comment
                        });
                      });
                  }}
                />
              </Table.Cell>
            );
          }}
        </Mutation>
      </Table.Row>
    );
  }
}
