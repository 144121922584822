import React from 'react';
import { Button } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';

export default function EventTab() {
  const { pathname } = useLocation();
  return (
    <Button.Group>
      <Button
        as={Link}
        to="/events"
        active={!pathname.includes('/heyground') && !pathname.includes('/opencollege')}
        content="전체"
      />
      <Button
        as={Link}
        to="/events/institution/heyground"
        active={pathname.includes('/events/institution/heyground')}
        content="소모임"
      />
      <Button
        as={Link}
        to="/events/institution/opencollege"
        active={pathname.includes('/events/institution/opencollege')}
        content="오픈컬리지"
      />
    </Button.Group>
  );
}
