import gql from 'graphql-tag';

const EventsQuery = gql`
  query Events($offset: Int, $limit: Int, $query: String, $institution: String) {
    events(offset: $offset, limit: $limit, query: $query, institution: $institution) {
      id
      title
      name
      created_at
      start_datetime
      end_datetime
      space {
        id
        name
      }
    }
  }
`;

export default EventsQuery;
