import React, { Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';
import { Header, Table } from 'semantic-ui-react';
import { ReservationHoursByInstitutionQuery } from '../../graphql/reports';

function ReservationHoursByInstitution({ from, to, category, categoryKo }) {
  const { loading, data } = useQuery(ReservationHoursByInstitutionQuery, {
    variables: { from, to, category },
  });
  if (loading) return null;
  if (!data || !data.reservationHoursByInstitution) return null;
  const { reservationHoursByInstitution } = data;
  const entries = JSON.parse(reservationHoursByInstitution);
  if (entries.length < 1) return null;
  const labels = [];
  const counts = [];
  entries.map(([key, value]) => {
    if (key) {
      labels.push(key);
    } else {
      labels.push('기타');
    }
    counts.push(value);
    return null;
  });
  const totalHours = counts.reduce((a, b) => a + b);
  const countsData = {
    labels,
    datasets: [
      {
        data: counts,
        backgroundColor: ['#ff6384', '#907aff', '#36a2eb', '#4bc0c0', '#ffce56'],
      },
    ],
  };
  return (
    <Fragment>
      <Header
        as="h2"
        content={`${categoryKo} 프로그램 이용 비율`}
        textAlign="center"
        className="mt-4"
      />
      <Pie data={countsData} />
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>프로그램 구분</Table.HeaderCell>
            <Table.HeaderCell>시간</Table.HeaderCell>
            <Table.HeaderCell>비중</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {entries.map(([key, value]) => (
            <Table.Row key={key}>
              <Table.Cell>{key || '기타'}</Table.Cell>
              <Table.Cell>{value}</Table.Cell>
              <Table.Cell>{Math.round((value / totalHours) * 100)}%</Table.Cell>
            </Table.Row>
          ))}
          <Table.Row>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell>{totalHours}</Table.Cell>
            <Table.Cell>100%</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Fragment>
  );
}

ReservationHoursByInstitution.propTypes = {
  from: PropTypes.number,
  to: PropTypes.number,
  category: PropTypes.string.isRequired,
  categoryKo: PropTypes.string.isRequired,
};

export default ReservationHoursByInstitution;
