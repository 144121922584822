import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React from 'react';
import { StoryForm } from '.';

export const CREATE_STORY = gql`
  mutation adminCreateStory($title: String, $content: String, $coverSignedId: String) {
    adminCreateStory(story: { title: $title, content: $content }, cover_signed_id: $coverSignedId) {
      id
      title
      content
      cover_url
      cover_filename
    }
  }
`;

function NewStory() {
  const [adminCreateStory, { loading }] = useMutation(CREATE_STORY);
  return <StoryForm submit={adminCreateStory} loading={loading} />;
}

export default NewStory;
