import React, { Fragment } from 'react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

const ReservationDetailTab = ({ page, status, history }) => {
  const statusText = status || 'all';
  return (
    <Fragment>
      <Button as={Link} to="/reservations/new" content="대관 추가하기" primary />
      <Button.Group>
        <Button
          as={Link}
          to={`/reservations/general/${statusText}/?page=${page}`}
          active={history.location.pathname.includes(`/reservations/general/${statusText}`)}
          content="요약"
        />
        <Button
          as={Link}
          to={`/reservations/detail/${statusText}/?page=${page}`}
          active={history.location.pathname.includes(`/reservations/detail/${statusText}`)}
          content="상세"
        />
      </Button.Group>
    </Fragment>
  );
};

export default withRouter(ReservationDetailTab);
