import gql from 'graphql-tag';

const AdminUpdateUserMutation = gql`
  mutation adminUpdateUser(
    $id: ID!
    $name: String
    $company: String
    $birthday: String
    $phone: String
    $email: String
    $category: String
    $category_ko: String
  ) {
    adminUpdateUser(
      id: $id
      user: {
        name: $name
        company: $company
        birthday: $birthday
        phone: $phone
        email: $email
        category: $category
        category_ko: $category_ko
      }
    ) {
      id
      name
      company
      birthday
      phone
      email
      category
      category_ko
    }
  }
`;

export default AdminUpdateUserMutation;
