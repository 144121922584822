import gql from 'graphql-tag';

const AdminLoginMutation = gql`
  mutation adminLogin($email: String, $password: String) {
    adminLogin(email: $email, password: $password) {
      token
      currentAdmin {
        id
      }
    }
  }
`;

export default AdminLoginMutation;
