import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { Grid, Icon, Statistic } from 'semantic-ui-react';
import { ReservationStatsQuery } from '../../graphql/reports';

function ReservationStats({ from, to }) {
  const { loading, data } = useQuery(ReservationStatsQuery, {
    variables: { from, to },
  });
  if (loading) return null;
  if (!data || !data.reservationStats) return null;
  const { reservationStats } = data;

  return (
    <>
      <Grid stackable columns={3} className="mt-1">
        <Grid.Column textAlign="center">
          <Statistic size="small">
            <Statistic.Value>
              <Icon name="calendar" />
              {` `}
              {reservationStats[0]}
            </Statistic.Value>
            <Statistic.Label style={{ marginTop: '0.5rem', fontWeight: 'normal' }}>
              누적 행사 수
            </Statistic.Label>
          </Statistic>
        </Grid.Column>

        <Grid.Column textAlign="center">
          <Statistic size="small">
            <Statistic.Value>
              <Icon name="time" />
              {` `}
              {reservationStats[1]}
            </Statistic.Value>
            <Statistic.Label style={{ marginTop: '0.5rem', fontWeight: 'normal' }}>
              누적 행사 시간
            </Statistic.Label>
          </Statistic>
        </Grid.Column>

        <Grid.Column textAlign="center">
          <Statistic size="small">
            <Statistic.Value>
              <Icon name="users" />
              {` `}
              {reservationStats[2]}
            </Statistic.Value>
            <Statistic.Label style={{ marginTop: '0.5rem', fontWeight: 'normal' }}>
              누적 방문자 수
            </Statistic.Label>
          </Statistic>
        </Grid.Column>
      </Grid>
    </>
  );
}

ReservationStats.propTypes = {
  from: PropTypes.number,
  to: PropTypes.number,
};

export default ReservationStats;
