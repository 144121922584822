import React, { Component } from 'react';
import { Button, Container, Form, Header } from 'semantic-ui-react';

class UserForm extends Component {
  constructor(props) {
    super(props);
    const { user } = props;
    if (user) {
      this.state = {
        id: Number(user.id),
        name: user.name,
        phone: user.phone,
        company: user.company
      };
    }
  }
  handleSubmit = () => {
    const { id, name, phone, company } = this.state;
    const { submit, history } = this.props;

    if (!name) {
      alert('이름을 입력해주세요.');
    } else if (!phone) {
      alert('연락처를 입력해주세요.');
    } else {
      const hash = {
        id,
        name,
        phone,
        company
      };
      if (window.confirm('정말로 회원 정보를 변경하시겠습니까?')) {
        submit({ variables: hash })
          .then(response => {
            if (response.data) {
              const link = `/users/${response.data.adminUpdateUser.id}`;
              history.push(link);
            }
          })
          .catch(error => {
            if (error.graphQLErrors && error.graphQLErrors.length > 0) {
              alert(error.graphQLErrors[0].message);
            }
          });
      }
    }
  };

  render() {
    const { name, phone, company } = this.state;

    return (
      <Container>
        <Form onSubmit={this.handleSubmit} size="large" className="ta-l">
          <Header as="h2" content="회원 정보 수정" className="mt-2" />
          <Form.Input
            className="mt-2"
            required
            fluid
            label="이름"
            placeholder="홍길동"
            value={name}
            onChange={e => this.setState({ name: e.target.value })}
          />
          <Form.Input
            className="mt-2"
            required
            fluid
            label="연락처"
            placeholder="010-1234-1234"
            value={phone}
            onChange={e => this.setState({ phone: e.target.value })}
          />
          {/* <Form.Input
            className="mt-2"
            required
            fluid
            label="이메일"
            placeholder="abc@xyz.xyz"
            value={email}
            onChange={e => this.setState({ email: e.target.value })}
          /> */}
          <Form.Input
            className="mt-2"
            fluid
            label="기관명"
            placeholder="없음"
            value={company}
            onChange={e => this.setState({ company: e.target.value })}
          />
          <Button
            className="mt-2"
            size="large"
            primary
            content="저장하기"
            type="submit"
          />
        </Form>
      </Container>
    );
  }
}

export default UserForm;
