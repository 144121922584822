import React from 'react';
import { Header, Table, Label, Loader } from 'semantic-ui-react';
import { Query } from 'react-apollo';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { UserReservationsQuery } from '../../graphql/users';
import { Time } from '../commons';

const UserReservations = ({ userId, history }) => {
  return (
    <Query query={UserReservationsQuery} variables={{ userId }}>
      {({ loading, data }) => {
        if (loading) return <Loader active inline="centered" className="mv-3" />;
        if (!data || !data.userReservations) return null;
        const { userReservations } = data;
        if (userReservations < 1) return null;
        return (
          <div className="pb-3">
            <Header size="medium" content="대관 신청내역" />
            <Table celled selectable unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>ID</Table.HeaderCell>
                  <Table.HeaderCell>대관일시</Table.HeaderCell>
                  <Table.HeaderCell>모임명</Table.HeaderCell>
                  <Table.HeaderCell>대관장소</Table.HeaderCell>
                  <Table.HeaderCell>생성일</Table.HeaderCell>
                  <Table.HeaderCell>수정일</Table.HeaderCell>
                  <Table.HeaderCell>상태</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {userReservations.map(reservation => {
                  const {
                    id,
                    start_datetime,
                    end_datetime,
                    title,
                    spaces,
                    created_at,
                    updated_at,
                    status,
                    status_color,
                  } = reservation;
                  return (
                    <Table.Row key={id} onClick={() => history.push(`/reservations/${id}`)}>
                      <Table.Cell>{id}</Table.Cell>
                      <Table.Cell>
                        {`${moment(start_datetime * 1000).format('LL (dd) a h:mm')} ~ ${moment(
                          end_datetime * 1000,
                        ).format('a h:mm')}`}
                      </Table.Cell>
                      <Table.Cell>{title}</Table.Cell>
                      <Table.Cell>
                        {spaces.map(space => {
                          return <Label key={space.id} content={space.name} />;
                        })}
                      </Table.Cell>
                      <Table.Cell>
                        <Time unix={created_at} format="LL" />
                      </Table.Cell>
                      <Table.Cell>
                        {!(created_at === updated_at) && <Time unix={updated_at} format="LL" />}
                      </Table.Cell>
                      <Table.Cell>
                        <Label size="mini" color={status_color} content={status} />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        );
      }}
    </Query>
  );
};

export default withRouter(UserReservations);
