import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Container, Dimmer, Image, Loader } from 'semantic-ui-react';
import { SplitContent, Time } from '../commons';

export const GET_STORY = gql`
  query Story($id: ID!) {
    story(id: $id) {
      id
      title
      content
      cover_url
      created_at
      updated_at
    }
  }
`;

function Story({ match }) {
  const { id } = match.params;
  const { loading, error, data } = useQuery(GET_STORY, {
    variables: { id },
  });
  if (loading)
    return (
      <Dimmer active inverted>
        <Loader active inline="centered" />
      </Dimmer>
    );
  if (error) return null;
  if (!data || !data.story) return null;
  const { story } = data;
  const { title, content, cover_url, created_at, updated_at } = story;
  return (
    <Container text className="pb-3">
      <Card fluid>
        {cover_url && <Image src={cover_url} fluid />}
        <Card.Content>
          <Card.Header>{title}</Card.Header>
        </Card.Content>
        <Card.Content>
          <Card.Description>
            <SplitContent content={content} />
          </Card.Description>
          <Card.Meta className="mt-1">
            <p>
              <strong>생성일시</strong> <Time unix={created_at} format="YYYY.M.D (dd) HH:mm" />
            </p>
            <p>
              <strong>수정일시</strong> <Time unix={updated_at} format="YYYY.M.D (dd) HH:mm" />
            </p>
          </Card.Meta>
        </Card.Content>
        <Card.Content extra>
          <Button as={Link} content="목록" to="/stories" />
          <Button primary as={Link} content="수정" to={`/stories/${id}/edit`} />
        </Card.Content>
      </Card>
    </Container>
  );
}

Story.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
};

export default Story;
