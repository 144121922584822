import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import BigCalendar from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Link } from 'react-router-dom';
import { Container, Icon, Label, Loader, Popup, Table } from 'semantic-ui-react';
import { TableRow } from '../commons';
import './calendar.css';

const EVENTS_QUERY = gql`
  query Reservations($date: Int) {
    calendarEvents(date: $date) {
      title
      start_datetime
      end_datetime
      space_id
      space {
        id
        name
      }
    }
    reservations(date: $date) {
      id
      start_datetime
      end_datetime
      spaces {
        id
        name
      }
      purpose_ko
      title
      category_ko
      category_color
      institution
      space_color
    }
  }
`;

const localizer = BigCalendar.momentLocalizer(moment);

function Calendar() {
  const [date, setDate] = useState(moment());
  const [agenda, setAgenda] = useState(false);

  const formats = {
    dateFormat: 'D',
    dayFormat: (d, _, loc) => loc.format(d, 'M/D (dd)', _),
    monthHeaderFormat: 'YYYY년 M월',
    agendaDateFormat: 'M/D(dd)',
    agendaTimeFormat: 'a h:mm',
    dayHeaderFormat: 'M월 D일 (dd)',
    dayRangeHeaderFormat: ({ start, end }, culture, local) =>
      `${local.format(start, 'YYYY년 M월 D일', culture)} ~ ${local.format(
        end,
        'YYYY년 M월 D일',
        culture,
      )}`,
    agendaTimeRangeFormat: ({ start, end }, culture, local) =>
      `${local.format(start, 'HH:mm', culture)}~${local.format(end, 'HH:mm', culture)}`,
    agendaHeaderFormat: ({ start, end }, culture, local) =>
      `${local.format(start, 'YYYY년 M월 D일', culture)} ~ ${local.format(
        end,
        'YYYY년 M월 D일',
        culture,
      )}`,
  };

  const { error, data } = useQuery(EVENTS_QUERY, {
    variables: { date: moment(date).unix() },
  });
  if (error) return null;
  if (!data || !data.reservations || !data.calendarEvents)
    return <Loader active inline="centered" />;

  let calendarReservations = [];
  const { reservations, calendarEvents } = data;
  if (reservations.length > 0) {
    calendarReservations = reservations.map(reservation => {
      return {
        id: reservation.id,
        start_datetime: moment(reservation.start_datetime * 1000).toDate(),
        end_datetime: moment(reservation.end_datetime * 1000).toDate(),
        title: reservation.title,
        spaces: reservation.spaces,
        purpose_ko: reservation.purpose_ko,
        category_ko: reservation.category_ko,
        category_color: reservation.category_color,
        institution: reservation.institution,
        is_manageable: reservation.is_manageable,
        space_color: reservation.space_color,
      };
    });
  }

  const openspaceEvents = calendarEvents
    .sort((a, b) => {
      return (
        a.space_id - b.space_id ||
        a.start_datetime - b.start_datetime ||
        a.end_datetime - b.end_datetime
      );
    })
    .reduce((r, a) => {
      const last = r[r.length - 1] || [];
      if (
        last.space_id === a.space_id &&
        last.start_datetime <= a.start_datetime &&
        a.start_datetime <= last.end_datetime
      ) {
        if (last.end_datetime < a.end_datetime) {
          last.end_datetime = a.end_datetime;
        }
        return r;
      }
      return r.concat(a);
    }, [])
    .map(event => {
      return {
        start_datetime: moment(event.start_datetime * 1000).toDate(),
        end_datetime: moment(event.end_datetime * 1000).toDate(),
        title: '오픈스페이스',
        spaces: [event.space],
        category_ko: '오픈스페이스',
      };
    });

  return (
    <Container fluid className="pb-3 calendar-container">
      <BigCalendar
        formats={formats}
        localizer={localizer}
        events={[...calendarReservations, ...openspaceEvents]}
        startAccessor="start_datetime"
        endAccessor="end_datetime"
        culture="ko"
        date={date.toDate()}
        messages={{
          today: '오늘',
          previous: <Icon name="angle left" color="grey" />,
          next: <Icon name="angle right" color="grey" />,
          date: '날짜',
          time: '시간',
          event: '모임',
          month: '월간',
          week: '주간',
          day: '일간',
          agenda: '목록',
          noEventsInRange: '등록된 모임이 없습니다.',
        }}
        onNavigate={(d, view) => {
          if (view === 'agenda') {
            setDate(
              moment(d)
                .add(5, 'day')
                .startOf('month'),
            );
          } else {
            setDate(moment(d));
          }
        }}
        onView={view => {
          if (view === 'agenda') {
            setAgenda(true);
          } else {
            setAgenda(false);
          }
        }}
        popup
        titleAccessor={event => {
          const {
            id,
            title,
            start_datetime,
            end_datetime,
            spaces,
            institution,
            category_ko,
            category_color,
            purpose_ko,
          } = event;
          if (agenda) {
            return (
              <span>
                <Label circular color={event.space_color} empty /> {event.title}
              </span>
            );
          }
          return (
            <Popup
              position="bottom left"
              verticalOffset={8}
              size="mini"
              on="click"
              style={{ right: 'none' }}
              trigger={
                <div>
                  <Label circular color={event.space_color} empty />{' '}
                  <strong>{moment(event.start_datetime).format('HH:mm')} </strong>
                  {event.title}
                </div>
              }
            >
              <Popup.Content>
                <Table basic="very" unstackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell colSpan="2" content={title} />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <TableRow title="일시">
                      {moment(start_datetime).format('LL dddd a h:mm')} ~{' '}
                      {moment(end_datetime).format('a h:mm')}
                    </TableRow>
                    <TableRow title="장소">
                      {spaces.map(space => {
                        return (
                          <Label
                            size="mini"
                            as={Link}
                            to={`/spaces/${space.id}`}
                            key={space.id}
                            content={space.name}
                          />
                        );
                      })}
                    </TableRow>
                    {purpose_ko && <TableRow title="목적">{purpose_ko}</TableRow>}
                    {institution && <TableRow title="기관">{institution}</TableRow>}
                    <TableRow title="유형">
                      <Label size="mini" content={category_ko} color={category_color} />
                    </TableRow>
                    {!!id && (
                      <Table.Row>
                        <Table.Cell className="text" verticalAlign="top" colSpan="2" width={16}>
                          <Link to={`/reservations/${id}`}>자세히 보기</Link>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Popup.Content>
            </Popup>
          );
        }}
      />
      <div className="mt-1">
        <span className="pr-1 gray">
          <Label circular color="teal" empty /> 다목적홀(영감)
        </span>
        <span className="pr-1 gray">
          <Label circular color="yellow" empty /> 세미나룸(공감)
        </span>
        <span className="pr-1 gray">
          <Label circular color="pink" empty /> 전체
        </span>
      </div>
    </Container>
  );
}

export default Calendar;
