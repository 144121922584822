import React from 'react';
import { Table } from 'semantic-ui-react';

const ReservationDetailHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>ID</Table.HeaderCell>
        <Table.HeaderCell>모임명</Table.HeaderCell>
        <Table.HeaderCell>대관일시</Table.HeaderCell>
        <Table.HeaderCell>장소</Table.HeaderCell>
        <Table.HeaderCell>카테고리</Table.HeaderCell>
        <Table.HeaderCell>이름</Table.HeaderCell>
        <Table.HeaderCell>기관</Table.HeaderCell>
        <Table.HeaderCell>이메일</Table.HeaderCell>
        <Table.HeaderCell>연락처</Table.HeaderCell>
        <Table.HeaderCell>예상인원</Table.HeaderCell>
        <Table.HeaderCell>대관목적</Table.HeaderCell>
        <Table.HeaderCell>공개여부</Table.HeaderCell>
        <Table.HeaderCell>유/무료 여부</Table.HeaderCell>
        <Table.HeaderCell>가격</Table.HeaderCell>
        <Table.HeaderCell>홍보유무</Table.HeaderCell>
        <Table.HeaderCell>홈페이지</Table.HeaderCell>
        <Table.HeaderCell>작성일</Table.HeaderCell>
        <Table.HeaderCell>승인상태</Table.HeaderCell>
        <Table.HeaderCell>승인여부</Table.HeaderCell>
        <Table.HeaderCell style={{ width: '100%' }}>비고</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

export default ReservationDetailHeader;
