import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import moment from 'moment';
import 'moment/locale/ko';
import { SendReservationRejectionMailMutation } from '../../graphql/reservations';

class RejectionMailButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonDisabled: false
    };
  }
  render() {
    const { event } = this.props;
    const { buttonDisabled } = this.state;
    const { id, rejection_mail_sent, end_datetime } = event;
    const isBefore = moment(end_datetime * 1000).isBefore(Date.now());
    if (rejection_mail_sent) {
      return (
        <Button disabled size="mini">
          발송 완료
        </Button>
      );
    }

    return (
      <Mutation mutation={SendReservationRejectionMailMutation}>
        {SendReservationRejectionMail => (
          <Button
            primary
            size="mini"
            disabled={isBefore || buttonDisabled}
            onClick={() => {
              if (
                window.confirm(
                  '발송 후에는 취소가 불가능합니다. 정말로 거절 메일을 발송하시겠습니까?'
                )
              ) {
                this.setState({ buttonDisabled: true });
                SendReservationRejectionMail({ variables: { id } });
              }
            }}
          >
            {' '}
            거절 메일 발송
          </Button>
        )}
      </Mutation>
    );
  }
}

export default RejectionMailButton;
