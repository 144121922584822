import _ from 'lodash';
import PropTypes from 'prop-types';
import * as qs from 'query-string';
import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import { Container, Input, Loader, Pagination, Table } from 'semantic-ui-react';
import { ReservationDetailTab, ReservationStatusTab } from '.';
import { AdminReservationsQuery, ReservationsCountQuery } from '../../graphql/reservations';
import { ReservationHeader, ReservationItem } from './items';

class Reservations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputText: '',
      query: '',
    };
    this.updateQuery = _.debounce(this.updateQuery, 300);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  updateQuery = () => {
    const { history, match } = this.props;
    const { status } = match.params;
    this.setState({
      query: this.state.inputText,
    });
    history.push(`/reservations/general/${status}`);
  };

  handleKeyDown = event => {
    const { history, match } = this.props;
    const { status } = match.params;
    const params = qs.parse(history.location.search);
    const page = params.page ? Number(params.page) : 1;

    switch (event.keyCode) {
      case 37:
        if (page > 1) {
          history.push(`/reservations/general/${status}/?page=${page - 1}`);
        }
        break;
      case 39:
        history.push(`/reservations/general/${status}/?page=${page + 1}`);
        break;
      default:
        break;
    }
  };

  render() {
    const { history, match } = this.props;
    const { status } = match.params;
    const params = qs.parse(history.location.search);
    const page = params.page ? Number(params.page) : 1;
    const { inputText, query } = this.state;

    return (
      <Container fluid className="pb-3">
        <ReservationDetailTab page={page} status={status} />
        <ReservationStatusTab />
        <Input
          className="mt-1"
          fluid
          focus
          icon="search"
          onChange={e => {
            this.setState({ inputText: e.target.value });
            this.updateQuery();
          }}
          placeholder="검색하기"
          value={inputText}
        />
        <Query
          query={AdminReservationsQuery}
          variables={{
            offset: 20 * (page - 1),
            status,
            query,
          }}
        >
          {({ loading, data }) => {
            if (loading) {
              return <Loader active inline="centered" className="mv-3" />;
            }
            if (!data || !data.adminReservations) return null;
            const { adminReservations } = data;
            return (
              <Fragment>
                <Table celled unstackable size="small">
                  <ReservationHeader />

                  <Table.Body>
                    {adminReservations.map(reservation => (
                      <ReservationItem key={reservation.id} reservation={reservation} />
                    ))}
                  </Table.Body>
                </Table>
                <Query
                  query={ReservationsCountQuery}
                  variables={{
                    status,
                    query,
                  }}
                >
                  {({ data: countData }) => {
                    if (!countData || !countData.reservationsCount) return null;
                    const { reservationsCount } = countData;
                    if (reservationsCount < 20) return null;
                    return (
                      <Pagination
                        activePage={page}
                        totalPages={reservationsCount ? Math.ceil(reservationsCount / 20) : 100}
                        onPageChange={(_e, { activePage }) => {
                          history.push(`/reservations/general/${status}/?page=${activePage}`);
                        }}
                      />
                    );
                  }}
                </Query>
              </Fragment>
            );
          }}
        </Query>
      </Container>
    );
  }
}

Reservations.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Reservations;
