import React from 'react';
import { Mutation } from 'react-apollo';
import { AdminCreateReservationMutation } from '../../graphql/reservations';
import { ReservationForm } from '../reservations';

const NewReservation = ({ history }) => {
  return (
    <Mutation mutation={AdminCreateReservationMutation}>
      {(adminCreateReservation, { loading }) => {
        return (
          <ReservationForm
            submit={adminCreateReservation}
            loading={loading}
            history={history}
          />
        );
      }}
    </Mutation>
  );
};

export default NewReservation;
